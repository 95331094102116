import React from "react";
import { ProcessedEvent } from "@aldabil/react-scheduler/types";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

const AppointmentItemCalendarView: React.FC<{
  event: ProcessedEvent;
  props: React.HTMLAttributes<HTMLDivElement>;
}> = ({ event, props }) => {
  const formattedState = event.state.charAt(0).toUpperCase() + event.state.slice(1);
  const currentUser = useSelector((state:RootState) => state.user.user);

  const displayName =
  currentUser?.firstName && currentUser?.lastName
      ? `${currentUser.firstName} ${currentUser.lastName}`
      : currentUser?.username || "Doctor";


  return (
    <div
      {...props}
      className="self-stretch h-full rounded box-border flex flex-col items-start justify-start p-2 gap-[4px] z-[3] border-2"
      style={{ borderColor: event.color, backgroundColor: `${event.color}33` }} // Light background of the same color
    >
      <div className="h-[20px] rounded-[3.55px] flex flex-row items-center justify-center py-1 px-[5px] box-border text-center text-xs font-bold text-white w-full"
        style={{ borderColor: event.color, borderWidth: '1px', backgroundColor: event.color }}
      >
        {`${event.start.toLocaleTimeString("en-US", {
          timeStyle: "short",
        })}-${event.end.toLocaleTimeString("en-US", { timeStyle: "short" })}`}
      </div>
      <div className="flex flex-col justify-evenly w-full h-full px-2">
        <div className="text-base font-semibold text-gray-700">{"Dr. " + displayName}</div>
        <div className="text-base text-gray-700">{formattedState}</div>
      </div>
    </div>
  );
};

export default AppointmentItemCalendarView;