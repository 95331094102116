import { FunctionComponent, useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../../redux/store";
import { RecordType } from "../../../redux/features/record/recordTypes";
import RecordTopView from "./RecordTopView";
import { RecordFormTextItem } from "./item/RecordFormTextItem";
import { RecordFormAmountItem } from "./item/RecordFormAmountItem";
import { RecordFormInsuranceItem } from "./item/RecordFormInsuranceItem";
import { RecordBottomView } from "./RecordBottomView";
import { handleSaveRecord, handleDeleteRecord } from "./record.utils";
import { UserRole } from "../../../redux/features/user/userTypes";

export type RecordConsultingViewType = {
  onClose: () => void;
};

const RecordConsultingView: FunctionComponent<RecordConsultingViewType> = ({
  onClose,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const currentUser = useSelector((state: RootState) => state.user.user);
  const currentPatient = useSelector(
    (state: RootState) => state.nav.currentPatient
  );
  const currentRecord = useSelector(
    (state: RootState) => state.nav.currentRecord
  );
  const currentAppointment = useSelector(
    (state: RootState) => state.nav.currentAppointment
  );

  const [form, setForm] = useState({
    symptom: "",
    diagnosis: "",
    notes: "",
  });
  const [fee, setFee] = useState("");
  const [showInsuranceFields, setShowInsuranceFields] = useState(
    currentRecord?.insurance ? true : false
  );
  const [insurance, setInsurance] = useState("");
  const [coverage, setCoverage] = useState("");
  const isDisabled = currentUser?.role === UserRole.Patient;

  useEffect(() => {
    if (currentRecord) {
      setForm(currentRecord.form);
      setFee(currentRecord.fee.toString());
      setInsurance(currentRecord.insurance || "");
      setCoverage(currentRecord.coverage?.toString() || "");
    }
  }, [currentRecord]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const handleFeeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFee(e.target.value);
  };

  const handleInsuranceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInsurance(e.target.value);
  };

  const handleCoverageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCoverage(e.target.value);
  };

  const toggleInsuranceFields = () => {
    setShowInsuranceFields(!showInsuranceFields);
  };

  const saveRecord = () => {
    handleSaveRecord(
      dispatch,
      currentPatient,
      currentUser,
      currentRecord,
      currentAppointment,
      form,
      fee,
      RecordType.Consulting,
      showInsuranceFields,
      insurance,
      coverage,
      onClose
    );
  };

  const deleteRecord = () => {
    handleDeleteRecord(dispatch, currentRecord, onClose);
  };

  return (
    <div className="w-[880px] shadow-[0px_0px_4px_2px_rgba(0,_0,_0,_0.1)] rounded-xl bg-white max-w-full overflow-hidden flex flex-col items-start justify-center pt-0 px-0 pb-5 box-border gap-[25px] text-left text-lg-9 text-strong font-inter">
      <RecordTopView
        onClose={onClose}
        onDelete={currentRecord ? deleteRecord : undefined}
      />
      <RecordFormTextItem
        label="Symptom:"
        name="symptom"
        value={form.symptom}
        onChange={handleChange}
      />
      <RecordFormTextItem
        label="Diagnosis:"
        name="diagnosis"
        value={form.diagnosis}
        onChange={handleChange}
      />
      <RecordFormTextItem
        label="Notes:"
        name="notes"
        value={form.notes}
        onChange={handleChange}
      />
      <RecordFormAmountItem
        label="Fee:"
        name="fee"
        value={fee}
        onChange={handleFeeChange}
      />
      {showInsuranceFields && (
        <RecordFormInsuranceItem
          insurance={insurance}
          coverage={coverage}
          onInsuranceChange={handleInsuranceChange}
          onCoverageChange={handleCoverageChange}
        />
      )}
      <RecordBottomView
        onClose={onClose}
        onSave={saveRecord}
        showInsurance={showInsuranceFields}
        toggleInsurance={toggleInsuranceFields}
        disableSave={
          !form.symptom ||
          !form.diagnosis ||
          !form.notes ||
          !fee ||
          (showInsuranceFields && (!insurance || !coverage))
        }
        currentRecord={currentRecord}
      />
    </div>
  );
};

export default RecordConsultingView;
