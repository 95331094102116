// src/components/auth/ForgotPassword.tsx

import { FunctionComponent, useState } from "react";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../../redux/features/auth/authSlice";
import TopNavBar from "../../components/nav/TopNavBar";
import { AppDispatch } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";

const ForgotPassword: FunctionComponent = () => {
  const dispatch: AppDispatch = useDispatch();
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      // Attempt to dispatch the forgotPassword action and wait for the result
      const resultAction = await dispatch(forgotPassword({ email }));
      unwrapResult(resultAction); // This will throw an error if the action fails
      navigate("/reset-password-email"); // Navigate only if the dispatch was successful
    } catch (error: any) {
      alert(error.message);
    }
  };

  return (
    <div className="w-full relative shadow-[0px_0px_124px_-15px_rgba(0,_0,_0,_0.25)] rounded-8xs bg-background-grey h-[1080px] overflow-hidden flex flex-col items-center justify-start gap-[100px]">
      <TopNavBar />
      <main className="w-[580px] rounded-[20.49px] bg-white h-[737px] overflow-hidden shrink-0 flex flex-col items-center justify-between pt-[30px] px-0 pb-0 box-border mix-blend-normal text-center text-11xl text-dimgray-200 font-inter">
        <div className="w-[489px] flex flex-col items-center justify-start gap-[6.8px]">
          <img
            className="w-[94.3px] relative h-[100px] object-cover"
            alt=""
            src="/logo/logo.png"
          />
          <h1 className="m-0 w-[561.7px] relative text-inherit font-medium font-inherit flex items-center justify-center">
            Forgot Password
          </h1>
        </div>
        <div className="w-[480px] flex-1 flex flex-col items-center justify-center gap-[20.5px] text-left text-sm-7">
          <div className="self-stretch flex flex-col items-start justify-start gap-[3.4px]">
            <div className="relative">Email address</div>
            <input
              className="[outline:none] bg-[transparent] self-stretch relative rounded-[10.24px] box-border h-[47.8px] overflow-hidden shrink-0 border-[0.9px] border-solid border-dimgray-500"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <button
            className="cursor-pointer p-[15px] bg-button-blue self-stretch rounded-3xs flex flex-row items-center justify-center"
            onClick={handleSubmit}
          >
            <div className="relative text-xl-2 font-semibold font-inter text-white text-left">
              Send Reset Link
            </div>
          </button>
        </div>
      </main>
    </div>
  );
};

export default ForgotPassword;
