import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";

const VerifyEmail: FunctionComponent = () => {
  const navigate = useNavigate();

  const onSignInClick = () => {
    navigate("/sign-in");
  };

  return (
    <div className="w-full h-screen flex flex-col items-center justify-center bg-background-grey">
      <div className="bg-white p-10 rounded-lg shadow-lg text-center">
        <h1 className="text-2xl font-semibold mb-4">Verify Your Email</h1>
        <p className="mb-6">
          We have sent a verification email to your email address. Please verify
          your email to continue.
        </p>
        <button
          className="bg-gray-600 text-white px-4 py-2 rounded-lg"
          onClick={onSignInClick}
        >
          Go to Sign In
        </button>
      </div>
    </div>
  );
};

export default VerifyEmail;
