// src/components/patient/view/PatientBookAppointmentView.tsx

import { FunctionComponent, useState, useEffect } from "react";
import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  SelectChangeEvent,
} from "@mui/material";
import PatientBookAppointmentTimeSlotItem from "../item/PatientBookAppointmentTimeSlotItem";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchDoctorAppointmentsById } from "../../../redux/features/appointment/appointmentSlice";
import { AppDispatch, RootState } from "../../../redux/store";
import { Appointment } from "../../../redux/features/appointment/appointmentTypes";
import { UserDoctor } from "../../../redux/features/user/userTypes";
import { Value } from "react-calendar/dist/cjs/shared/types";
import PatientBookAppointmentPopupView from "../item/PatientBookAppointmentPopupView";

const PatientBookAppointmentView: FunctionComponent = () => {
  const dispatch: AppDispatch = useDispatch();
  const allDoctors = useSelector((state: RootState) => state.user.allDoctors);
  const doctorAppointments = useSelector(
    (state: RootState) => state.appointment.doctorAppointments
  );
  const [selectedDoctor, setSelectedDoctor] = useState<string>("");
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedAppointmentId, setSelectedAppointmentId] = useState<
    number | null
  >(null);

  useEffect(() => {
    if (allDoctors && allDoctors.length > 0) {
      setSelectedDoctor(allDoctors[0].id.toString());
      dispatch(fetchDoctorAppointmentsById(allDoctors[0].id));
    }
  }, [allDoctors, dispatch]);

  const handleDoctorChange = (event: SelectChangeEvent<string>) => {
    const doctorId = event.target.value as string;
    setSelectedDoctor(doctorId);
    dispatch(fetchDoctorAppointmentsById(parseInt(doctorId)));
  };

  const handleDateChange = (value: Value) => {
    const date = value as Date;
    setSelectedDate(date);
  };

  const handleTimeSlotClick = (appointmentId: number) => {
    setSelectedAppointmentId(appointmentId);
  };

  const timeSlots = doctorAppointments
    .filter(
      (appointment: Appointment) =>
        new Date(appointment.timeFrom).toDateString() ===
        selectedDate.toDateString()
    )
    .map((appointment: Appointment) => ({
      time: new Date(appointment.timeFrom).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      }),
      appointmentId: appointment.id,
    }));

  return (
    <>
      <style>
        {`
          .react-calendar {
            border: none;
          }
          .appointment-section {
            height: 100%; /* Adjusted to use full height */
            flex: 1 1 50%;
            max-width: 50%;
          }
          .appointment-section-title {
            margin-bottom: 10px;
          }
          .calendar-container, .timeslot-container {
            height: 100%;
            width: 100%;
            background-color: white;
            border-radius: 12px;
            padding: 20px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
          .timeslot-grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 10px;
            width: 100%;
          }
        `}
      </style>
      <div className="w-full">
        <div className="w-full flex flex-row items-center justify-start py-2.5 px-5 box-border min-w-[1100px] max-w-[1450px] mq450:gap-[196px] mb-5">
          <h2 className="m-0 w-[368px] relative text-inherit tracking-[-0.01em] leading-[20px] font-bold font-inherit inline-block shrink-0 max-w-full mq450:text-3xl mq450:leading-[27px]">
            Book an Appointment
          </h2>
        </div>
        <div className="self-stretch flex flex-row items-center justify-start py-0 px-5 box-border min-w-[580px] max-w-full shrink-0 mq750:gap-[466px] mq450:gap-[233px] mb-5">
          <FormControl
            className="h-[49px] w-[618px] font-inter font-medium text-mid-3 text-gray-100 max-w-full"
            variant="filled"
            sx={{
              borderRadius: "4px",
              width: "618px",
              "& .MuiInputBase-root": {
                height: "49px",
                alignItems: "center",
                backgroundColor: "white",
              },
              "& .MuiSelect-select": {
                height: "49px",
                display: "flex",
                alignItems: "center",
                backgroundColor: "lightgrey",
              },
            }}
          >
            <InputLabel color="primary">Select Doctor</InputLabel>
            <Select
              value={selectedDoctor}
              onChange={handleDoctorChange}
              disableUnderline
              inputProps={{ "aria-label": "Without label" }}
            >
              {allDoctors.map((doctor: UserDoctor) => (
                <MenuItem key={doctor.id} value={doctor.id.toString()}>
                  {doctor.firstName} {doctor.lastName} - {doctor.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <section className="self-stretch flex flex-row items-start justify-start py-[26.5px] px-5 box-border gap-[40px] shrink-0 max-w-full text-left text-xl text-neutral-gray-dark-200 font-inter lg:flex-nowrap mq750:gap-[20px] mq750:pt-5 mq750:pb-5 mq750:box-border">
          <div className="appointment-section rounded-xl bg-neutral-white flex flex-col items-start justify-start py-5 px-5 box-border lg:min-w-[562px] max-w-full lg:flex-1 mq750:min-w-full">
            <div className="appointment-section-title flex flex-row items-center justify-start">
              <h3 className="m-0 relative text-inherit tracking-[0.09px] leading-[17.06px] font-bold font-inherit mq450:text-base mq450:leading-[14px]">
                Select Preferred Date
              </h3>
            </div>
            <div className="calendar-container">
              <Calendar
                className="w-full"
                onChange={handleDateChange}
                value={selectedDate}
                tileClassName={({ date, view }) => {
                  if (
                    doctorAppointments.some(
                      (appointment: Appointment) =>
                        new Date(appointment.timeFrom).toDateString() ===
                        date.toDateString()
                    )
                  ) {
                    return "bg-green-200"; // Custom class for days with open appointments
                  }
                  return "";
                }}
              />
            </div>
          </div>
          <div className="appointment-section rounded-xl bg-neutral-white flex flex-col items-start justify-start py-5 px-5 box-border lg:min-w-[467px] max-w-full lg:flex-1 mq750:min-w-full">
            <div className="appointment-section-title flex flex-row items-center justify-start">
              <h3 className="m-0 relative text-inherit tracking-[0.09px] leading-[18px] font-bold font-inherit mq450:text-base mq450:leading-[14px]">
                Select Time Slot
              </h3>
            </div>
            <div className="timeslot-container">
              <div className="timeslot-grid">
                {timeSlots.length > 0 ? (
                  <div className="timeslot-grid">
                    {timeSlots.map(
                      (
                        {
                          time,
                          appointmentId,
                        }: { time: string; appointmentId: number },
                        index: number
                      ) => (
                        <PatientBookAppointmentTimeSlotItem
                          key={index}
                          time={time}
                          appointmentId={appointmentId}
                          onClick={() => handleTimeSlotClick(appointmentId)}
                        />
                      )
                    )}
                  </div>
                ) : (
                  <div>No available time slots today</div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
      {selectedAppointmentId !== null && (
        <PatientBookAppointmentPopupView
          appointmentId={selectedAppointmentId}
          onClose={() => setSelectedAppointmentId(null)}
        />
      )}
    </>
  );
};

export default PatientBookAppointmentView;
