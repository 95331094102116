import { FunctionComponent } from "react";
import { GroupByPeriod } from "../../../redux/features/record/recordTypes";
import logger from "../../../util/logger";

type AdminDashboardViewItemProps = {
  icon: string;
  title: string;
  amount: string;
  percentage: string;
  percentageColor?: string;
  groupBy: GroupByPeriod;
  onClick: () => void;
};

const AdminDashboardViewItem: FunctionComponent<
  AdminDashboardViewItemProps
> = ({
  icon,
  title,
  amount,
  percentage,
  groupBy,
  percentageColor = "text-mediumseagreen",
  onClick,
}) => {
  logger.log("amount", amount);
  return (
    <div
      className="flex-1 rounded-xl bg-white h-[155px] flex flex-col items-start justify-between p-5 box-border min-w-[220px] max-w-[300px] cursor-pointer"
      onClick={onClick}
    >
      <div className="self-stretch flex flex-row items-center justify-start gap-[16px]">
        <div className="flex flex-row items-center justify-center relative gap-[10px]">
          <div className="w-10 relative rounded-[50%] bg-red h-10 opacity-[0.1] z-[0]" />
          <img
            className="w-6 absolute !m-[0] top-[8px] left-[8px] h-6 object-cover z-[1]"
            alt=""
            src={icon}
          />
        </div>
        <div className="relative font-medium">{title}</div>
      </div>
      <div className="self-stretch flex flex-row items-center justify-start gap-[5px] text-11xl">
        <b className="relative">
          {amount !== "undefined" ? amount : "not availabe"}
        </b>
      </div>
      <div
        className={`w-[238px] flex flex-row items-center justify-start gap-[4px] text-sm ${percentageColor}`}
      >
        <img className="w-3.5 relative h-3.5" alt="" src="/arrowupicon.svg" />
        <b className="relative">{percentage}</b>
        <div className="relative text-gray-200">
          compare to{" "}
          {groupBy !== GroupByPeriod.Day ? `last ${groupBy}` : "yesterday"}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardViewItem;
