import { createSlice, createAsyncThunk, isPending, isRejectedWithValue } from '@reduxjs/toolkit';
import { apiRequest } from '../../api';
import { Record, CreateRecordDto, UpdateRecordDto, SearchRecordDto, RecordSearchType, StatisticRecord } from './recordTypes';
import { fetchUser } from '../user/userSlice';

interface RecordState {
  patientRecords: Record[];
  userRecords: Record[];
  appointmentRecords: Record[];
  clients: StatisticRecord | null;
  cashflows: StatisticRecord | null;
  treatments: StatisticRecord | null;
  insurances: StatisticRecord | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: RecordState = {
  patientRecords: [],
  userRecords: [],
  appointmentRecords: [],
  clients: null,
  cashflows: null,
  treatments: null,
  insurances: null,
  isLoading: false,
  error: null,
};
  
  
export const fetchRecordStatistics = createAsyncThunk(
  'records/fetchRecordStatistics',
  async (searchRecordDto: SearchRecordDto) => {
    const response = await apiRequest({ url: `records/search`, method: 'GET', params: searchRecordDto });
    return { data: response, periodsNum: searchRecordDto.periods, groupBy: searchRecordDto.groupBy, startPeriod: searchRecordDto.startPeriod, endPeriod: searchRecordDto.endPeriod }; // Include groupBy, startPeriod, and endPeriod in response
  }
);

export const fetchRecordsByAppointmentId = createAsyncThunk(
  'records/fetchRecordsByAppointmentId',
  async (appointmentId: number) => {
    const response = await apiRequest({ url: `records/appointment/${appointmentId}`, method: 'GET' });
    return response as Record[];
  }
);

export const fetchUserRecords = createAsyncThunk('records/fetchUserRecords', async ({ userId, type }: { userId: number, type: 'creator' | 'owner' }) => {
  const response = await apiRequest({ url: `records/user/${userId}?type=${type}`, method: 'GET' });
  return response as Record[];
});

export const fetchPatientRecords = createAsyncThunk('records/fetchPatientRecords', async ({ userId, type }: { userId: number, type: 'creator' | 'owner' }) => {
    const response = await apiRequest({ url: `records/user/${userId}?type=${type}`, method: 'GET' });
    return response as Record[];
  });
  
export const createRecord = createAsyncThunk('records/createRecord', async (recordData: CreateRecordDto, { dispatch }) => {
  const response = await apiRequest({ url: 'records', method: 'POST', body: recordData });
  dispatch(fetchUser());
  return response as Record;
});

export const updateRecord = createAsyncThunk('records/updateRecord', async ({ recordId, updateData }: { recordId: number; updateData: UpdateRecordDto }, { dispatch }) => {
  const response = await apiRequest({ url: `records/${recordId}`, method: 'PATCH', body: updateData });
  dispatch(fetchUser());
  return response as Record;
});

export const deleteRecord = createAsyncThunk('records/deleteRecord', async (recordId: number) => {
  await apiRequest({ url: `records/${recordId}`, method: 'DELETE' });
  return recordId;
});

const recordSlice = createSlice({
  name: 'records',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserRecords.fulfilled, (state, action) => {
        state.userRecords = action.payload;
      })
      .addCase(createRecord.fulfilled, (state, action) => {
        state.patientRecords.push(action.payload);
      })
      .addCase(fetchPatientRecords.fulfilled, (state, action) => {
        state.patientRecords = action.payload;
      })      
      .addCase(fetchRecordsByAppointmentId.fulfilled, (state, action) => {
        state.appointmentRecords = action.payload;
      })
      .addCase(updateRecord.fulfilled, (state, action) => {
        const index = state.patientRecords.findIndex(record => record.id === action.payload.id);
        if (index !== -1) {
          state.patientRecords[index] = action.payload;
        }
      })
      .addCase(deleteRecord.fulfilled, (state, action) => {
        state.patientRecords = state.patientRecords.filter(record => record.id !== action.payload);
      })
      .addCase(fetchRecordStatistics.fulfilled, (state, action) => {
        const { type } = action.meta.arg;
        const { data, groupBy, startPeriod, endPeriod, periodsNum } = action.payload;
        const statisticRecord = { ...data, groupBy, startPeriod, endPeriod, periodsNum };
        switch (type) {
          case RecordSearchType.Client:
            state.clients = statisticRecord;
            break;
          case RecordSearchType.Cashflow:
            state.cashflows = statisticRecord;
            break;
          case RecordSearchType.Treatment:
            state.treatments = statisticRecord;
            break;
          case RecordSearchType.Insurance:
            state.insurances = statisticRecord;
            break;
          default:
            break;
        }
      })
      .addMatcher(isPending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addMatcher(isRejectedWithValue, (state, action) => {
        state.isLoading = false;
        state.error = 'An error occurred';
      })
      .addMatcher(
        (action) => action.type.endsWith('/fulfilled'),
        (state) => {
          state.isLoading = false;
          state.error = null;
        }
      );
  },
});

export default recordSlice.reducer;
