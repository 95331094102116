// src/pages/PatientDashboard.tsx
import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import TopNavBar from "../../components/nav/TopNavBar";
import PatientUpcomingAppointmentView from "../../components/patient/view/PatientUpcomingAppointmentView";
import PatientBookAppointmentView from "../../components/patient/view/PatientBookAppointmentView";
import PatientDashboardNavBar from "../../components/patient/nav/PatientDashboardNavBar";
import PatientAppointmentHistoryView from "../../components/patient/view/PatientAppointmentHistoryView";

const PatientDashboardPage: FunctionComponent = () => {
  const currentView = useSelector((state: RootState) => state.nav.dashboard);

  return (
    <div className="w-full h-screen flex flex-col">
      <TopNavBar />
      <main className="flex-grow overflow-auto flex flex-col items-center justify-start pt-[30px] px-10 box-border gap-[40px] max-w-full text-left text-9xl text-gray-300 font-inter">
        <PatientDashboardNavBar />
        {currentView === "UpcomingAppointment" && (
          <PatientUpcomingAppointmentView />
        )}
        {currentView === "BookAppointment" && (
          <PatientBookAppointmentView />
        )}
        {currentView === "AppointmentHistory" && (
          <PatientAppointmentHistoryView />
        )}
      </main>
    </div>
  );
};

export default PatientDashboardPage;
