// src/utils/record.utils.ts
import { AppDispatch, RootState } from "../../../redux/store";
import {
  createRecord,
  updateRecord,
  deleteRecord,
} from "../../../redux/features/record/recordSlice";
import { Record, RecordType } from "../../../redux/features/record/recordTypes";

export const handleSaveRecord = async (
  dispatch: AppDispatch,
  currentPatient: any,
  currentUser: any,
  currentRecord: any,
  currentAppointment: any,
  form: any,
  fee: string,
  type: RecordType,
  showInsuranceFields: boolean,
  insurance: string,
  coverage: string,
  onClose?: () => void
) => {
  
  if (currentPatient && currentUser) {
    const recordData = {
      form,
      fee: parseFloat(fee),
      type,
      insurance: showInsuranceFields ? insurance : null,
      coverage: showInsuranceFields ? parseFloat(coverage) : 0,
      isCovered: showInsuranceFields && insurance && coverage ? true : false,
      appointmentId: currentAppointment ? currentAppointment.id : undefined,
    };

    if (currentRecord) {
      dispatch(
        updateRecord({ recordId: currentRecord.id, updateData: recordData })
      );
    } else {
      dispatch(createRecord({ ...recordData, ownerId: currentPatient.id }));
    }
    if (onClose) onClose();
  }
};

export const handleDeleteRecord = async (
  dispatch: AppDispatch,
  currentRecord: any,
  onClose?: () => void
) => {
  if (currentRecord) {
    dispatch(deleteRecord(currentRecord.id));
    if (onClose) onClose();
  }
};
