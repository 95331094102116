import { FunctionComponent } from "react";
import { UserRole } from "../../../redux/features/user/userTypes";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";

export type RecordBottomViewProps = {
  onClose: () => void;
  onSave: () => void;
  showInsurance: boolean;
  toggleInsurance: () => void;
  disableSave: boolean;
  hideInsuranceButton?: boolean;
  currentRecord: any;
};

export const RecordBottomView: FunctionComponent<RecordBottomViewProps> = ({
  onClose,
  onSave,
  showInsurance,
  toggleInsurance,
  disableSave,
  hideInsuranceButton = false,
  currentRecord,
}) => {
  const currentUser = useSelector((state: RootState) => state.user.user);
  const disabled = currentUser?.role === UserRole.Patient;
  return (
    <div className="self-stretch flex flex-row items-center justify-between py-0 px-[30px] box-border min-w-[300px]">
      {!disabled && (
        <>
          <button
            className="cursor-pointer py-[15px] px-[25px] bg-[transparent] rounded-3xs flex flex-row items-center justify-start border-[1px] border-solid border-cancel-red"
            onClick={onClose}
          >
            <b className="relative text-sm tracking-[0.2px] leading-[18px] font-inter text-cancel-red text-center">
              Cancel
            </b>
          </button>
          <div className="flex flex-row items-center justify-end gap-[10px]">
            {!hideInsuranceButton && (
              <button
                className={`cursor-pointer py-[15px] px-[25px] rounded-3xs flex flex-row items-center justify-start border-[1px] border-solid ${
                  showInsurance
                    ? "bg-button-blue text-white"
                    : "bg-gray-200 text-gray-700"
                }`}
                onClick={toggleInsurance}
              >
                <b className="relative text-sm tracking-[0.2px] leading-[18px] font-inter">
                  Insurance
                </b>
              </button>
            )}
            <button
              className="cursor-pointer [border:none] py-[15px] px-[25px] bg-button-blue rounded-3xs flex flex-row items-center justify-start"
              onClick={onSave}
            >
              <b className="relative text-sm tracking-[0.2px] leading-[18px] font-inter text-white text-center">
                {currentRecord ? "Edit Record" : "Add Record"}
              </b>
            </button>
          </div>
        </>
      )}
    </div>
  );
};
