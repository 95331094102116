// src/components/PatientCancelAppointmentFailedView.tsx
import { FunctionComponent } from "react";
import PatientItemTopView from "./PatientItemTopView";

interface PatientCancelAppointmentFailedViewProps {
  onClose: () => void;
}

const PatientCancelAppointmentFailedView: FunctionComponent<
  PatientCancelAppointmentFailedViewProps
> = ({ onClose }) => {
  return (
    <div
      className={`w-[800px] shadow-[0px_0px_4px_2px_rgba(0,_0,_0,_0.1)] rounded-xl bg-white max-w-full h-[485px] overflow-hidden flex flex-col items-center justify-between pt-0 px-0 pb-5 box-border text-justify text-xl text-neutral-black-300 font-inter`}
    >
      <PatientItemTopView newAppointmentTitle="Cancel Appointment" />
      <div className="self-stretch h-[266px] flex flex-col items-center justify-center py-0 px-[30px] box-border">
        <b className="relative tracking-[0.2px] leading-[26px]">
          <p className="m-0">{`Unfortunately, we are unable to process your appointment cancellation request `}</p>
          <p className="m-0">
            as it was made less than 24 hours before your scheduled time.
          </p>
          <p className="m-0">
            As we reserve specific time slots for each Patients, any failure to
            show up
          </p>
          <p className="m-0">
            without prior notice may result in penalty for future services.
          </p>
        </b>
      </div>
      <div className="self-stretch flex flex-row items-center justify-between py-0 px-[30px]">
        <button
          className="cursor-pointer py-[15px] px-[25px] bg-[transparent] rounded-3xs flex flex-row items-center justify-start border-[1px] border-solid border-button-blue"
          onClick={onClose}
        >
          <b className="relative text-sm tracking-[0.2px] leading-[18px] font-inter text-button-blue text-center">
            Cancel
          </b>
        </button>
        <button
          className="cursor-pointer [border:none] py-[15px] px-[25px] bg-cancel-red rounded-3xs flex flex-row items-center justify-start"
          onClick={onClose}
        >
          <b className="relative text-sm tracking-[0.2px] leading-[18px] font-inter text-neutral-white text-center">
            Return
          </b>
        </button>
      </div>
    </div>
  );
};

export default PatientCancelAppointmentFailedView;
