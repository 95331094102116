import React, { ChangeEvent } from "react";

interface PatientInfoFormEntryItemProps {
  labelEN: string;
  lableCN: String;
  name: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  disabled?: boolean;
}

const PatientInfoFormEntryItem: React.FC<PatientInfoFormEntryItemProps> = ({
  labelEN,
  lableCN,
  name,
  value,
  onChange,
  disabled = false,
}) => {
  return (
    <div className="flex-1 h-[82px] flex flex-row items-center justify-start min-w-[180px] text-center text-lg-9 text-strong font-inter">
      <div className="self-stretch w-[180px] box-border flex flex-col items-center justify-center py-5 px-0 border-[0.5px] border-solid border-menu-text-gray">
        <div className="self-stretch relative">
          <p className="m-0">{labelEN}</p>
          <p className="m-0">{lableCN}</p>
        </div>
      </div>
      <div className="self-stretch flex-1 flex flex-row items-center justify-start p-5 text-left text-base-4 border-[0.5px] border-solid border-menu-text-gray">
        <input
          className="[outline:none] bg-[transparent] flex-1 box-border py-[30.5px] px-5 font-inter text-[16.4px] text-strong"
          placeholder={labelEN}
          name={name}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default PatientInfoFormEntryItem;
