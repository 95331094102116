// src/redux/features/user/userTypes.ts

export enum UserRole {
  Patient = 'patient',
  Doctor = 'doctor',
  Admin = 'admin'
}

export const UserRolePermissions = {
  [UserRole.Patient]: 1,
  [UserRole.Doctor]: 2,
  [UserRole.Admin]: 3
};

export interface UserState {
  user: User | null;
  doctors: User[] | null;
  patients: User[] | null;
  allDoctors: UserDoctor[] | null;
  isLoading: boolean;
  error: any;
}

export interface User {
  id: number;
  email: string;
  username: string;
  discriminator: string;
  firstName: string | null;
  lastName: string | null;
  phoneNumber: string | null;
  registrationNumber?: string;
  createDate: Date;
  lastLogin: Date | null;
  isActive: boolean;
  title: string;
  emailVerified: boolean;
  role: UserRole;
  settings: UserSettings;
  userMetaData: UserMetaData;
  address: Address| null;
  status: string | null;
  balance: number;
  services: DoctorService;
  description: string;

}

export interface UserSettings {}

export interface UserMetaData {
  gender?: string;
  dateEmployed?: Date;
  msp?: number;
  dateOfBirth?: Date;
  medicalHistory?: string[];
  familyDoctorName?: string;
  familyDoctorPhoneNumber?: string;
  emergencyContactName?: string;
  emergencyContactPhoneNumber?: string;
}

export interface UpdateUserDto {
  username?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  registrationNumber?: string;
  status?: string;
  dateEmployed?: Date;
  title?: string;
  address?: Address;
  description?: string;
  msp?: number;
  balance?: number;
  dateOfBirth?: Date;
  services?: DoctorService;
  settings?: UserSettings;
  userMetaData?: UserMetaData;
}

export interface CreateUserDto {
  email: string;
  username: string;
  password: string;
  role: UserRole;
  firstName?: string;
  lastName?: string;
  registrationNumber?: string;
  phoneNumber?: string;
  address?: Address;
  status?: string;
  title?: string;
  description?: string;
  msp?: number;
  services?: DoctorService;
  settings?: UserSettings;
  userMetaData?: UserMetaData;
}

export interface DoctorService {
  acupuncture: boolean;
  herb: boolean;
  massage: boolean;
}

export interface Address{
  city: string;
  zip: string;
  province: string;
  street: string;
}

export interface UserDoctor {
  id: number;
  firstName: string;
  lastName: string;
  title: string;
  services: DoctorService;
}