import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { saveStateToLocalStorage, loadStateFromLocalStorage } from "../../localStorage";
import { NavState } from "./navTypes";
import { User } from "../user/userTypes";
import { Record } from "../record/recordTypes";
import { Appointment } from "../appointment/appointmentTypes";

const initialState: NavState = loadStateFromLocalStorage("navState") || {
  dashboard: "TodayAppointment",
  setting: "Profile",
  menu: "Dashboard",
  currentPatient: null,
  currentRecord: null,
  currentDoctor: null,
  currentAppointment: null,
};

const navSlice = createSlice({
  name: "nav",
  initialState,
  reducers: {
    setDashboard: (state, action: PayloadAction<NavState["dashboard"]>) => {
      state.dashboard = action.payload;
      saveStateToLocalStorage("navState", state);
    },
    setSetting: (state, action: PayloadAction<NavState["setting"]>) => {
      state.setting = action.payload;
      saveStateToLocalStorage("navState", state);
    },
    //set current menu bar
    setMenu: (state, action: PayloadAction<NavState["menu"]>) => {
      state.menu = action.payload;
      saveStateToLocalStorage("navState", state);
    },
    setCurrentPatient: (state, action: PayloadAction<User | null>) => {
      state.currentPatient = action.payload;
      saveStateToLocalStorage("navState", state);
    },
    setCurrentDoctor: (state, action: PayloadAction<User | null>) => {
      state.currentDoctor = action.payload;
      saveStateToLocalStorage("navState", state);
    },
    setCurrentAppointment: (state, action: PayloadAction<Appointment | null>) => {
      state.currentAppointment = action.payload;
      saveStateToLocalStorage("navState", state);
    },
    clearCurrentDoctor: (state) => {
      state.currentDoctor = null;
    },
    setCurrentRecord: (state, action: PayloadAction<Record | null>) => {
      state.currentRecord = action.payload;
      saveStateToLocalStorage("navState", state);
    },
  },
});

export const { setDashboard, setSetting, setMenu, setCurrentPatient, setCurrentRecord, setCurrentDoctor, clearCurrentDoctor, setCurrentAppointment } = navSlice.actions;
export default navSlice.reducer;
