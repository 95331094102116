import React, { FunctionComponent, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { AgGridReact } from "ag-grid-react";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Button } from "@mui/material";
import { format } from "date-fns";
import {
  Appointment,
  AppointmentState,
} from "../../../redux/features/appointment/appointmentTypes";
import DoctorAppointmentRecordsPopup from "../item/DoctorAppointmentRecordsPopup";

const DoctorAppointmentHistoryView: FunctionComponent = () => {
  const appointments = useSelector(
    (state: RootState) => state.appointment.appointmentsHistory
  );

  const [selectedAppointmentId, setSelectedAppointmentId] = useState<
    number | null
  >(null);

  const columnDefs: ColDef[] = [
    {
      headerName: "Name",
      field: "participants[0].firstName", // Assuming the first participant is the patient
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        const participant = params.data.participants[0];
        return participant.firstName && participant.lastName
          ? `${participant.firstName} ${participant.lastName}`
          : participant.username;
      },
    },
    {
      headerName: "Phone",
      field: "participants[0].phoneNumber", // Assuming the first participant is the patient
      flex: 1,
      minWidth: 150,
      valueGetter: (params) =>
        params.data.participants[0]?.phoneNumber || "N/A",
    },
    {
      headerName: "Appointment Time",
      field: "timeFrom",
      flex: 2,
      minWidth: 200,
      valueFormatter: (params) =>
        format(new Date(params.value), "MMM dd, yyyy, hh:mm a"),
    },
    {
      headerName: "Action",
      field: "id",
      cellRenderer: (params: ICellRendererParams) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setSelectedAppointmentId(params.value)}
        >
          View Records
        </Button>
      ),
    },
  ];

  return (
    <div className="w-full">
      <h2 className="m-0 mb-4 relative text-inherit tracking-[-0.01em] leading-[20px] font-bold font-inherit">
        Appointment History
      </h2>
      <div className="ag-theme-alpine" style={{ height: 400, width: "100%" }}>
        <AgGridReact
          rowData={appointments}
          columnDefs={columnDefs}
          domLayout="autoHeight"
        />
      </div>
      {selectedAppointmentId !== null && (
        <DoctorAppointmentRecordsPopup
          appointmentId={selectedAppointmentId}
          onClose={() => setSelectedAppointmentId(null)}
        />
      )}
    </div>
  );
};

export default DoctorAppointmentHistoryView;
