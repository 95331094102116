// src/components/doctor/item/DoctorAppointmentRecordsPopup.tsx
import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PortalPopup from "../../patient-info/record/PortalPopup";
import { fetchRecordsByAppointmentId } from "../../../redux/features/record/recordSlice";
import { AppDispatch, RootState } from "../../../redux/store";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import RecordPopup from "../../patient-info/record/RecordPopup";
import { setCurrentRecord } from "../../../redux/features/nav/navSlice";

interface DoctorAppointmentRecordsPopupProps {
  appointmentId: number;
  onClose: () => void;
}

const DoctorAppointmentRecordsPopup: FunctionComponent<
  DoctorAppointmentRecordsPopupProps
> = ({ appointmentId, onClose }) => {
  const dispatch: AppDispatch = useDispatch();
  const records = useSelector(
    (state: RootState) => state.record.appointmentRecords
  );

  const [showPopup, setShowPopup] = useState<string | null>(null);

  useEffect(() => {
    dispatch(fetchRecordsByAppointmentId(appointmentId));
  }, [dispatch, appointmentId]);

  const columnDefs: ColDef[] = [
    {
      headerName: "Date",
      field: "createDate",
      valueFormatter: (params) => new Date(params.value).toLocaleDateString(),
    },
    { headerName: "Record Type", field: "type" },
    { headerName: "Fee", field: "fee" },
    {
      headerName: "Doctor Name",
      field: "creator",
      valueFormatter: (params) =>
        params.value
          ? `${params.value.firstName} ${params.value.lastName}`
          : "N/A",
    },
    {
      headerName: "Action",
      field: "id",
      cellRenderer: (params: ICellRendererParams) => (
        <button
          onClick={() => {
            dispatch(setCurrentRecord(params.data));
            setShowPopup(params.data.type);
          }}
          className="rounded-8xs bg-button-blue text-white px-2 py-1"
        >
          View
        </button>
      ),
    },
  ];

  return (
    <PortalPopup onOutsideClick={onClose}>
      <div className="popup-container bg-white p-5 rounded-md">
        <h2>Appointment Records</h2>
        <div className="ag-theme-alpine" style={{ height: 300, width: 900 }}>
          <AgGridReact
            rowData={records}
            columnDefs={columnDefs}
            domLayout="autoHeight"
          />
        </div>
        <button onClick={onClose}>Close</button>
        <RecordPopup type={showPopup} onClose={() => setShowPopup(null)} />
      </div>
    </PortalPopup>
  );
};

export default DoctorAppointmentRecordsPopup;
