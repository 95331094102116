// src/components/doctor/item/DoctorAppointmentDisplayItem.tsx
import React, { FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import { Appointment } from "../../../redux/features/appointment/appointmentTypes";
import {
  setCurrentPatient,
  setCurrentAppointment,
} from "../../../redux/features/nav/navSlice";
import { useNavigate } from "react-router-dom";

interface DoctorAppointmentDisplayItemProps {
  appointment: Appointment;
}

const DoctorAppointmentDisplayItem: FunctionComponent<
  DoctorAppointmentDisplayItemProps
> = ({ appointment }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = () => {
    if (appointment.participants.length > 0) {
      dispatch(setCurrentPatient(appointment.participants[0]));
      dispatch(setCurrentAppointment(appointment));
      navigate("/patient-info");
    }
  };

  return (
    <div
      onClick={handleClick}
      className="cursor-pointer rounded-xl bg-white h-[155px] flex flex-col items-start justify-between p-5 box-border text-left text-xs-9 text-neutral-gray-dark font-inter"
    >
      <div className="flex flex-row items-center justify-start text-mini">
        <b className="relative tracking-[0.09px] leading-[17.06px]">
          {appointment.participants.length
            ? appointment.participants[0].firstName
              ? `${appointment.participants[0].firstName} ${appointment.participants[0].lastName}`
              : appointment.participants[0].username
            : ""}
        </b>
      </div>
      <div className="relative tracking-[0.09px] leading-[17.06px]">
        {new Date(appointment.timeFrom).toLocaleString()}
      </div>
      <b className="w-[198px] relative tracking-[0.09px] leading-[17.06px] inline-block text-neutral-black whitespace-pre-wrap">
        Treatment: {appointment.description}
      </b>
      <div className="relative tracking-[0.09px] leading-[17.06px] whitespace-pre-wrap">{`Window: ${new Date(
        appointment.timeFrom
      ).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      })} - ${new Date(appointment.timeTo).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      })}`}</div>
      <b className="w-[190px] relative tracking-[0.09px] leading-[17.06px] inline-block text-neutral-black whitespace-pre-wrap">
        State: {appointment.state}
      </b>
    </div>
  );
};

export default DoctorAppointmentDisplayItem;
