export const saveStateToLocalStorage = <T>(key: string, state: T): void => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem(key, serializedState);
    } catch (e) {
      console.error('Could not save state', e);
    }
  };
  
  export const loadStateFromLocalStorage = (key: string) => {
    try {
      const serializedState = localStorage.getItem(key);
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (e) {
      console.error('Could not load state', e);
      return undefined;
    }
  };
  
  export const removeStateFromLocalStorage = (key: string): void => {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      console.error('Could not remove state', e);
    }
  };
  
  export function clearLocalStorage(): void {
    try {
      localStorage.clear();
    } catch (error) {
      console.error('Error clearing local storage', error);
    }
  }