import React, { FunctionComponent, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { AgGridReact } from "ag-grid-react";
import { ColDef, ICellRendererParams, GridReadyEvent } from "ag-grid-community";
import { Button } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Appointment } from "../../../redux/features/appointment/appointmentTypes";
import PatientAppointmentRecordsPopup from "../item/PatientAppointmentRecordsPopup";
import { format } from "date-fns";

const PatientAppointmentHistoryView: FunctionComponent = () => {
  const appointments = useSelector((state: RootState) =>
    state.appointment.appointments.filter(
      (appointment: Appointment) => new Date(appointment.timeTo) < new Date()
    )
  );

  const [selectedAppointmentId, setSelectedAppointmentId] = useState<
    number | null
  >(null);

  const columnDefs: ColDef[] = [
    {
      headerName: "Name",
      field: "host.firstName",
      valueGetter: (params) =>
        params.data.host?.firstName
          ? `${params.data.host?.firstName} ${params.data.host?.lastName}`
          : params.data.host?.username,
      flex: 1,
    },
    { headerName: "Purpose", field: "description", flex: 1 },
    {
      headerName: "Appointment Time",
      field: "timeFrom",
      flex: 2,
      minWidth: 200,
      valueFormatter: (params) =>
        format(new Date(params.value), "MMM dd, yyyy, hh:mm a"),
    },
    {
      headerName: "View",
      field: "id",
      cellRenderer: (params: ICellRendererParams) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setSelectedAppointmentId(params.value)}
        >
          View Records
        </Button>
      ),
      width: 100,
    },
  ];

  const onGridReady = (params: GridReadyEvent) => {
    params.api.sizeColumnsToFit();
  };

  return (
    <div className="w-full">
      <div className="w-full flex flex-row items-center justify-start py-2.5 px-5 box-border min-w-[1100px] max-w-[1450px] mb-5">
        <h2 className="m-0 w-[368px] relative text-inherit tracking-[-0.01em] leading-[20px] font-bold font-inherit inline-block shrink-0 max-w-full">
          Appointment History
        </h2>
      </div>
      <div className="ag-theme-alpine" style={{ width: "100%" }}>
        <AgGridReact
          rowData={appointments}
          columnDefs={columnDefs}
          domLayout="autoHeight"
          onGridReady={onGridReady}
          rowHeight={40}
        />
      </div>
      {selectedAppointmentId !== null && (
        <PatientAppointmentRecordsPopup
          appointmentId={selectedAppointmentId}
          onClose={() => setSelectedAppointmentId(null)}
        />
      )}
    </div>
  );
};

export default PatientAppointmentHistoryView;
