import { FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { setDashboard } from "../../../redux/features/nav/navSlice";

const PatientDashboardNavBar: FunctionComponent = () => {
  const dispatch = useDispatch();
  const currentView = useSelector((state: RootState) => state.nav.dashboard);

  const handleButtonClick = (view: "BookAppointment" | "UpcomingAppointment" | "AppointmentHistory") => {
    dispatch(setDashboard(view));
  };

  const buttonHeightClass = "h-[140px]"; // Adjusted height to match the provided DoctorDashboardNavBar

  return (
    <div className="w-full max-w-full flex flex-row items-center justify-between p-2 box-border gap-[20px]">
      <button
        className={`cursor-pointer [border:none] p-4 ${
          currentView === "UpcomingAppointment" ? "bg-fill" : "bg-neutral-white"
        } ${buttonHeightClass} flex-1 rounded-xl flex flex-col items-center justify-center box-border min-w-[320px] max-w-[400px]`}
        onClick={() => handleButtonClick("UpcomingAppointment")}
      >
        <div className="flex flex-col items-center justify-center gap-[4px]">
          <div className="flex flex-row items-center justify-center gap-[8px]">
            <img className="w-12 h-12" alt="" src="/treamenticon-1.svg" />
            <div className="text-lg font-medium font-inter text-gray-300">Upcoming Appointments</div>
          </div>
        </div>
      </button>
      <button
        className={`cursor-pointer [border:none] p-4 ${
          currentView === "BookAppointment" ? "bg-fill" : "bg-neutral-white"
        } ${buttonHeightClass} flex-1 rounded-xl flex flex-col items-center justify-center box-border min-w-[320px] max-w-[400px]`}
        onClick={() => handleButtonClick("BookAppointment")}
      >
        <div className="flex flex-col items-center justify-center gap-[4px]">
          <div className="flex flex-row items-center justify-center gap-[8px]">
            <img className="w-12 h-12" alt="" src="/treamenticon-3.svg" />
            <div className="text-lg font-medium font-inter text-gray-300">Book an Appointment</div>
          </div>
        </div>
      </button>
      <button
        className={`cursor-pointer [border:none] p-4 ${
          currentView === "AppointmentHistory" ? "bg-fill" : "bg-neutral-white"
        } ${buttonHeightClass} flex-1 rounded-xl flex flex-col items-center justify-center box-border min-w-[320px] max-w-[400px]`}
        onClick={() => handleButtonClick("AppointmentHistory")}
      >
        <div className="flex flex-col items-center justify-center gap-[4px]">
          <div className="flex flex-row items-center justify-center gap-[8px]">
            <img className="w-12 h-12" alt="" src="/treamenticon-3.svg" />
            <div className="text-lg font-medium font-inter text-gray-300">Appointment History</div>
          </div>
        </div>
      </button>
    </div>
  );
};

export default PatientDashboardNavBar;