import { AppointmentState } from "./redux/features/appointment/appointmentTypes";

export const getBackgroundColor = (state: AppointmentState): string => {
  switch (state) {
    case AppointmentState.Open:
      return "#62E067"; // limegreen
    case AppointmentState.Scheduled:
      return "#33BFFF"; // blue
    case AppointmentState.NotAvailable:
      return "#E92F2F"; // crimson
    case AppointmentState.Pending:
      return "#FFF500"; // yellow
    default:
      return "#D3D3D3"; // gray
  }
};
