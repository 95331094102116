import { FunctionComponent, useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../../redux/store";
import RecordTopView from "./RecordTopView";
import { RecordFormTextItem } from "./item/RecordFormTextItem";
import { RecordFormAmountItem } from "./item/RecordFormAmountItem";
import { RecordFormInsuranceItem } from "./item/RecordFormInsuranceItem";
import { RecordType } from "../../../redux/features/record/recordTypes";
import { handleSaveRecord, handleDeleteRecord } from "./record.utils";
import { RecordBottomView } from "./RecordBottomView";
import { UserRole } from "../../../redux/features/user/userTypes";

export type RecordAcupunctureViewType = {
  onClose: () => void;
};

const RecordAcupunctureView: FunctionComponent<RecordAcupunctureViewType> = ({
  onClose,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const currentUser = useSelector((state: RootState) => state.user.user);
  const currentPatient = useSelector(
    (state: RootState) => state.nav.currentPatient
  );
  const currentRecord = useSelector(
    (state: RootState) => state.nav.currentRecord
  );
  const currentAppointment = useSelector(
    (state: RootState) => state.nav.currentAppointment
  );

  const [form, setForm] = useState({
    symptom: "",
    diagnosis: "",
    points: "",
    notes: "",
    services: {
      cupping: false,
      guaSha: false,
      initial: false,
    },
  });
  const [fee, setFee] = useState("");
  const [showInsuranceFields, setShowInsuranceFields] = useState(
    currentRecord?.insurance ? true : false
  );
  const [insurance, setInsurance] = useState("");
  const [coverage, setCoverage] = useState("");

  useEffect(() => {
    if (currentRecord) {
      setForm(currentRecord.form);
      setFee(currentRecord.fee.toString());
      setInsurance(currentRecord.insurance || "");
      setCoverage(currentRecord.coverage?.toString() || "");
    }
  }, [currentRecord]);
  const isDisabled = currentUser?.role === UserRole.Patient;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const handleServiceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      services: { ...prevForm.services, [name]: checked },
    }));
  };

  const handleFeeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFee(e.target.value);
  };

  const handleInsuranceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInsurance(e.target.value);
  };

  const handleCoverageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (parseFloat(e.target.value) > parseFloat(fee)) {
      return; // Prevent coverage from being more than the fee
    }
    setCoverage(e.target.value);
  };

  const toggleInsuranceFields = () => {
    setShowInsuranceFields(!showInsuranceFields);
  };

  const handleSave = () => {
    handleSaveRecord(
      dispatch,
      currentPatient,
      currentUser,
      currentRecord,
      currentAppointment,
      form,
      fee,
      RecordType.Acupuncture,
      showInsuranceFields,
      insurance,
      coverage,
      onClose
    );
  };

  return (
    <div className="w-[880px] shadow-[0px_0px_4px_2px_rgba(0,_0,_0,_0.1)] rounded-xl bg-white max-w-full overflow-hidden flex flex-col items-start justify-center pt-0 px-0 pb-5 box-border gap-[25px] text-left text-lg-9 text-strong font-inter">
      <RecordTopView
        onClose={onClose}
        onDelete={
          currentRecord
            ? () => handleDeleteRecord(dispatch, currentRecord, onClose)
            : undefined
        }
      />
      <RecordFormTextItem
        label="Symptom:"
        name="symptom"
        value={form.symptom}
        onChange={handleChange}
      />
      <RecordFormTextItem
        label="Diagnosis:"
        name="diagnosis"
        value={form.diagnosis}
        onChange={handleChange}
      />
      <RecordFormTextItem
        label="Acupuncture points:"
        name="points"
        value={form.points}
        onChange={handleChange}
      />
      <RecordFormTextItem
        label="Notes:"
        name="notes"
        value={form.notes}
        onChange={handleChange}
      />
      <div className="w-full flex flex-row items-center justify-between py-0 px-[30px] box-border min-w-[750px] max-w-[900px]">
        <div className="flex flex-row items-center justify-start gap-[30px]">
          <div className="w-[120px] relative inline-block shrink-0">{`Services: `}</div>
          <div className="flex flex-row items-start justify-start gap-[18px] text-base">
            <div className="flex flex-row items-center justify-start gap-[6px]">
              <input
                className="m-0 w-6 relative h-6"
                type="checkbox"
                name="cupping"
                checked={form.services.cupping}
                onChange={handleServiceChange}
                disabled={isDisabled}
              />
              <div className="relative">Cupping</div>
            </div>
            <div className="flex flex-row items-center justify-start gap-[6px]">
              <input
                className="m-0 w-6 relative h-6"
                type="checkbox"
                name="guaSha"
                checked={form.services.guaSha}
                onChange={handleServiceChange}
                disabled={isDisabled}
              />
              <div className="relative">GuaSha</div>
            </div>
            <div className="flex flex-row items-center justify-start gap-[6px]">
              <input
                className="m-0 w-6 relative h-6"
                type="checkbox"
                name="initial"
                checked={form.services.initial}
                onChange={handleServiceChange}
                disabled={isDisabled}
              />
              <div className="relative">Initial</div>
            </div>
          </div>
        </div>
        <RecordFormAmountItem
          label="Fee:"
          name="fee"
          value={fee}
          onChange={handleFeeChange}
        />
      </div>
      {showInsuranceFields && (
        <RecordFormInsuranceItem
          insurance={insurance}
          coverage={coverage}
          onInsuranceChange={handleInsuranceChange}
          onCoverageChange={handleCoverageChange}
        />
      )}
      <RecordBottomView
        onClose={onClose}
        onSave={handleSave}
        showInsurance={showInsuranceFields}
        toggleInsurance={toggleInsuranceFields}
        disableSave={
          !form.symptom ||
          !form.diagnosis ||
          !form.points ||
          !form.notes ||
          !fee ||
          (!form.services.cupping &&
            !form.services.guaSha &&
            !form.services.initial) ||
          (showInsuranceFields && (!insurance || !coverage))
        }
        currentRecord={currentRecord}
      />
    </div>
  );
};

export default RecordAcupunctureView;
