import React, { useEffect } from "react";
import { Provider, useSelector, useDispatch } from "react-redux";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
  Navigate,
} from "react-router-dom";
import ForgotPassword from "./pages/auth/ForgotPassword";
import DoctorDashboardPage from "./pages/doctor/DoctorDashboardPage";
import PatientDashboardPage from "./pages/patient/PatientDashboardPage";
import PatientInfoPage from "./pages/patient-info/PatientInfoPage";
import DoctorPatientPage from "./pages/doctor/DoctorPatientPage";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import SettingPage from "./pages/setting/SettingPage";
import AdminDashboardPage from "./pages/admin/AdminDashboardPage";
import AdminDoctorAccountsPage from "./pages/admin/AdminDoctorAccountsPage";
import AdminPatientAccountsPage from "./pages/admin/AdminPatientAccountsPage";
import AddDoctorPage from "./pages/admin/AddDoctorPage";
import { AppDispatch, RootState } from "./redux/store";
import store from "./redux/store";
import { injectStore } from "./redux/api";
import VerifyEmail from "./pages/auth/VerifyEmail";
import {
  fetchDoctors,
  fetchPatients,
  fetchUser,
  getAllDoctors,
} from "./redux/features/user/userSlice";
import { UserRole } from "./redux/features/user/userTypes";
import { fetchAppointments } from "./redux/features/appointment/appointmentSlice";
import PatientInfoFormPage from "./pages/patient-info/PatientInfoFormPage";
import ResetPassword from "./pages/auth/ResetPassword";
import ResetPasswordEmail from "./pages/auth/ResetPasswordEmail";
import logger from "./util/logger";
function AppContent() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  const userRole = useSelector((state: RootState) => state.user.user?.role);
  const user = useSelector((state: RootState) => state.user.user);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    if (userRole) {
      if (userRole === UserRole.Doctor) {
        logger.log("fetching doctor data");
        dispatch(fetchAppointments({ userType: "all" }));
        dispatch(fetchPatients());
      }
      if (userRole === UserRole.Admin) {
        logger.log("fetching admin data");
        dispatch(fetchDoctors());
        dispatch(fetchPatients());
        dispatch(fetchAppointments({ userType: "all" }));
      }
      if (userRole === UserRole.Patient) {
        dispatch(getAllDoctors());
        dispatch(fetchAppointments({ userType: "participant" }));
      }
    }
  }, [dispatch, userRole]);
  useEffect(() => {
    logger.log("isLoggedIn", isLoggedIn);
    if (isLoggedIn) {
      dispatch(fetchUser());
    }
  }, [isLoggedIn, dispatch, userRole]);

  const renderRoutesForRole = (role: string | undefined) => {
    switch (role) {
      case UserRole.Admin:
        return (
          <>
            <Route path="/dashboard" element={<AdminDashboardPage />} />
            <Route
              path="/doctor-accounts"
              element={<AdminDoctorAccountsPage />}
            />
            <Route
              path="/patient-accounts"
              element={<AdminPatientAccountsPage />}
            />
            <Route path="/add-doctor" element={<AddDoctorPage />} />
            <Route path="/setting" element={<SettingPage />} />

            {/* Doctor related pages*/}
            <Route path="/doctor-dashboard" element={<DoctorDashboardPage />} />
            <Route path="/patient-info" element={<PatientInfoPage />} />
            <Route path="/patients" element={<DoctorPatientPage />} />
            {/* Patient related pages*/}
            <Route
              path="/patient-dashboard"
              element={<PatientDashboardPage />}
            />
            <Route
              path="/patient-info-form"
              element={<PatientInfoFormPage />}
            />

            <Route path="*" element={<Navigate to="/dashboard" />} />
          </>
        );
      case UserRole.Doctor:
        return (
          <>
            <Route path="/dashboard" element={<DoctorDashboardPage />} />
            <Route path="/patient-info" element={<PatientInfoPage />} />
            <Route
              path="/patient-info-form"
              element={<PatientInfoFormPage />}
            />
            <Route path="/patients" element={<DoctorPatientPage />} />
            <Route path="/setting" element={<SettingPage />} />
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </>
        );
      case UserRole.Patient:
        return (
          <>
            <Route path="/dashboard" element={<PatientDashboardPage />} />
            <Route path="/patient-info" element={<PatientInfoPage />} />
            <Route path="/info-form" element={<PatientInfoFormPage />} />
            <Route path="/setting" element={<SettingPage />} />
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Routes>
      {!isLoggedIn ? (
        <>
          <Route path="/" element={<SignIn />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route
            path="/reset-password-email"
            element={<ResetPasswordEmail />}
          />
          <Route path="*" element={<Navigate to="/sign-in" />} />
        </>
      ) : (
        renderRoutesForRole(userRole)
      )}
    </Routes>
  );
}

injectStore(store);

const App = () => {
  return (
    <Provider store={store}>
      <AppContent />
    </Provider>
  );
};

export default App;
