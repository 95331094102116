
const formatMessage = (...messages: any[]) => {
  return messages.map(msg => (typeof msg === 'object' ? JSON.stringify(msg) : msg)).join(' ');
};

const logger = {
  log: (...messages: any[]) => {
    const message = formatMessage(...messages);
    if (process.env.REACT_APP_ENV === 'dev') {
      logger.log(message);
    }
  },
  error: (...messages: any[]) => {
    const message = formatMessage(...messages);
    if (process.env.REACT_APP_ENV === 'dev') {
        console.error(message);
    } 
  },
  info: (...messages: any[]) => {
    const message = formatMessage(...messages);
    if (process.env.REACT_APP_ENV === 'dev') {
      console.info(message);
    }
  },
  debug: (...messages: any[]) => {
    const message = formatMessage(...messages);
    if (process.env.REACT_APP_ENV === 'dev') {
      console.debug(message);
    }
  },
  warn: (...messages: any[]) => {
    const message = formatMessage(...messages);
    if (process.env.REACT_APP_ENV === 'dev') {
      console.warn(message);
    }
  },
};

export default logger;
