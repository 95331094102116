// src/components/PatientBookAppointmentPopupView.tsx
import { FunctionComponent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAppointment } from "../../../redux/features/appointment/appointmentSlice";
import { RootState, AppDispatch } from "../../../redux/store";
import PatientItemTopView from "../item/PatientItemTopView";
import PortalPopup from "../../patient-info/record/PortalPopup";
import {
  Appointment,
  AppointmentState,
} from "../../../redux/features/appointment/appointmentTypes";

interface PatientBookAppointmentPopupViewProps {
  appointmentId: number;
  onClose: () => void;
}

const PatientBookAppointmentPopupView: FunctionComponent<
  PatientBookAppointmentPopupViewProps
> = ({ appointmentId, onClose }) => {
  const dispatch: AppDispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);
  const appointment = useSelector((state: RootState) =>
    state.appointment.doctorAppointments.find(
      (appt: Appointment) => appt.id === appointmentId
    )
  );
  const [purpose, setPurpose] = useState("");

  const handleConfirm = () => {
    if (user && appointment) {
      dispatch(
        updateAppointment({
          appointmentId,
          updateData: {
            participants: [...appointment.participants, user.id],
            state: AppointmentState.Pending,
            description: purpose,
          },
        })
      );
      onClose();
    }
  };

  if (!appointment) return null;

  return (
    <PortalPopup
      overlayColor="rgba(113, 113, 113, 0.3)"
      placement="Centered"
      onOutsideClick={onClose}
    >
      <div className="w-[678px] shadow-[0px_0px_4px_2px_rgba(0,_0,_0,_0.1)] rounded-xl bg-white max-w-full overflow-hidden flex flex-col items-center justify-start pt-0 px-0 pb-5 box-border gap-[25px] text-left text-xl text-neutral-black-300 font-inter">
        <PatientItemTopView newAppointmentTitle="Book Appointment" />
        <div className="self-stretch h-[66px] flex flex-col items-start justify-between py-0 px-[30px] box-border">
          <div className="flex flex-row items-center justify-start gap-[5px]">
            <b className="relative tracking-[0.2px] leading-[26px]">
              Book your appointment with Dr.
            </b>
            <b className="relative tracking-[0.2px] leading-[26px]">
              {appointment.doctorName}
            </b>
          </div>
          <b className="self-stretch relative tracking-[0.2px] leading-[26px] inline-block h-[27px] shrink-0">
            {new Date(appointment.timeFrom).toLocaleString([], {
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}{" "}
            -{" "}
            {new Date(appointment.timeTo).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </b>
        </div>
        <div className="self-stretch h-[66px] flex flex-col items-start justify-center py-0 px-[30px] box-border">
          <div className="flex flex-row items-center justify-start">
            <div className="relative tracking-[0.2px] leading-[26px]">
              <p className="m-0 font-light">
                Because we reserve a time especially for your, please make any
              </p>
              <p className="m-0">
                <span className="font-light">schedule changes </span>
                <b className="font-inter">24-Hours before appointment</b>
                <span className="font-light">
                  {" "}
                  to avoid incurring a late cancellation fee
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start py-0 px-[30px]">
          <input
            className="[outline:none] font-medium font-inter text-mid-3 bg-neutral-white flex-1 relative rounded-3xs box-border h-[67px] pt-[15px] px-[15px] pb-2.5 text-gray-100 border-[1px] border-solid border-text-field-surround-grey"
            placeholder="Purpose of Appointment"
            type="text"
            value={purpose}
            onChange={(e) => setPurpose(e.target.value)}
          />
        </div>
        <div className="self-stretch flex flex-row items-center justify-between py-0 px-[30px]">
          <button
            className="cursor-pointer py-[15px] px-[25px] bg-[transparent] rounded-3xs flex flex-row items-center justify-start border-[1px] border-solid border-cancel-red"
            onClick={onClose}
          >
            <b className="relative text-sm tracking-[0.2px] leading-[18px] font-inter text-cancel-red text-center">
              Cancel
            </b>
          </button>
          <button
            className="cursor-pointer [border:none] py-[15px] px-[25px] bg-button-blue rounded-3xs flex flex-row items-center justify-start"
            onClick={handleConfirm}
          >
            <b className="relative text-sm tracking-[0.2px] leading-[18px] font-inter text-neutral-white text-center">
              Book Appointment
            </b>
          </button>
        </div>
      </div>
    </PortalPopup>
  );
};

export default PatientBookAppointmentPopupView;
