// src/components/doctor/view/DoctorCalendarView.tsx
import React, { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Scheduler } from "@aldabil/react-scheduler";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  GetAppointmentsDto,
  Appointment,
  UpdateAppointmentDto,
} from "../../../redux/features/appointment/appointmentTypes";
import {
  fetchAppointments,
  deleteAppointment,
  updateAppointment,
} from "../../../redux/features/appointment/appointmentSlice";
import AppointmentEditorView from "../item/AppointmentEditorView";
import AppointmentItemCalendarView from "../item/AppointmentItemCalendarView";
import { DragEvent } from "react";
import { ProcessedEvent } from "@aldabil/react-scheduler/types";
import { getBackgroundColor } from "../../../utils";

const DoctorCalendarView: FunctionComponent = () => {
  const dispatch: AppDispatch = useDispatch();
  const { appointments } = useSelector((state: RootState) => state.appointment);
  const { user } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (user) {
      const getAppointmentsDto: GetAppointmentsDto = { userType: "all" };
      dispatch(fetchAppointments(getAppointmentsDto));
    }
  }, [dispatch, user]);

  const handleDelete = async (deletedId: string | number) => {
    await dispatch(deleteAppointment(Number(deletedId)));
  };

  const handleEventDrop = async (
    event: DragEvent<HTMLButtonElement>,
    droppedOn: Date,
    updatedEvent: ProcessedEvent,
    originalEvent: ProcessedEvent
  ) => {
    const updatedData: UpdateAppointmentDto = {
      timeFrom: new Date(updatedEvent.start),
      timeTo: new Date(updatedEvent.end),
      description: updatedEvent.description,
      state: updatedEvent.state,
    };
    await dispatch(
      updateAppointment({
        appointmentId: Number(updatedEvent.event_id),
        updateData: updatedData,
      })
    );
  };

  return (
    <div className="w-full">
      <h2 className="m-0 mb-4 relative text-inherit tracking-[-0.01em] leading-[20px] font-bold font-inherit">
        Calendar
      </h2>
      <div style={{ paddingTop: "20px" }}>
        {" "}
        {/* Add padding or margin to ensure spacing */}
        <Scheduler
          view="week"
          events={appointments.map((appt: Appointment) => ({
            event_id: appt.id,
            title: appt.description,
            start: new Date(appt.timeFrom),
            end: new Date(appt.timeTo),
            state: appt.state,
            color: getBackgroundColor(appt.state),
            participant: appt.participants[0],
          }))}
          customEditor={(scheduler) => (
            <AppointmentEditorView scheduler={scheduler} />
          )}
          onDelete={handleDelete}
          onEventDrop={handleEventDrop}
          eventRenderer={(props) => (
            <AppointmentItemCalendarView event={props.event} props={props} />
          )}
        />
      </div>
    </div>
  );
};

export default DoctorCalendarView;
