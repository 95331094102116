import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { UserRole } from "../../../redux/features/user/userTypes";

type RecordTopViewProps = {
  onClose?: () => void;
  onDelete?: () => void;
};

const RecordTopView: FunctionComponent<RecordTopViewProps> = ({
  onClose,
  onDelete,
}) => {
  const currentPatient = useSelector(
    (state: RootState) => state.nav.currentPatient
  );
  const currentRecord = useSelector(
    (state: RootState) => state.nav.currentRecord
  );
  const currentUser = useSelector((state: RootState) => state.user.user);
  const disabled = currentUser?.role === UserRole.Patient;
  return (
    <div className="self-stretch box-border flex flex-row items-center justify-between p-5 min-w-[400px] text-left text-xl text-neutral-black-300 font-inter border-b-[1px] border-solid border-menu-line-grey">
      <b className="w-[172px] relative tracking-[0.2px] leading-[26px] inline-block shrink-0">
        {currentPatient
          ? `${currentPatient.firstName} ${currentPatient.lastName}`
          : "Patient Name"}
      </b>
      <div className="flex flex-row items-end justify-start gap-[35px] text-lg-9 text-strong">
        <b className="relative">{new Date().toLocaleDateString()}</b>
        {currentRecord && !disabled && (
          <button
            className="cursor-pointer [border:none] p-[5px] bg-[transparent] overflow-hidden flex flex-row items-end justify-start"
            onClick={onDelete}
          >
            <img
              className="w-[14.4px] relative h-[14.4px]"
              alt=""
              src="/popup/deleteicon.svg"
            />
          </button>
        )}
        <button
          className="cursor-pointer [border:none] p-[5px] bg-[transparent] overflow-hidden flex flex-row items-end justify-start"
          onClick={onClose}
        >
          <img
            className="w-[14.4px] relative h-[14.4px]"
            alt=""
            src="/popup/cancelicon.svg"
          />
        </button>
      </div>
    </div>
  );
};

export default RecordTopView;
