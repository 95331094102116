import React, { FunctionComponent } from "react";

export type BillingItemType = {
  date: string;
  reason: string;
  amount: string;
};

const BillingItem: FunctionComponent<BillingItemType> = ({
  date,
  reason,
  amount,
}) => {
  return (
    <div className="flex flex-row items-center justify-between text-left text-base text-gray-700 font-lato self-stretch">
      <div className="relative font-semibold">{date}</div>
      <div className="relative font-semibold">{reason}</div>
      <div className="relative font-semibold">{amount}</div>
    </div>
  );
};

export default BillingItem;
