// src/components/setting/view/ResetPasswordView.tsx

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetPassword } from "../../../redux/features/auth/authSlice";
import OldPswView from "../item/SettingPasswordItem";
import { setSetting } from "../../../redux/features/nav/navSlice";
import { AppDispatch, RootState } from "../../../redux/store";
import { useSelector } from "../../../redux/hooks";

const SettingResetPasswordView: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const currentUser = useSelector((state: RootState) => state.user.user);

  const handleSubmit = () => {
    if (newPassword !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    const resetPasswordData = {
      oldPassword,
      newPassword,
    };

    dispatch(resetPassword({ dto: resetPasswordData, userId: currentUser.id }));
    dispatch(setSetting("Profile"));
  };

  return (
    <div className="w-[544px] flex-1 flex flex-col items-center justify-center gap-[41px] text-center text-29xl text-gray-500 font-inter">
      <div className="self-stretch relative font-semibold">Reset Password</div>
      <div className="self-stretch flex flex-col items-start justify-start gap-[3.4px]">
        <div className="relative">Old Password</div>
        <input
          className="[outline:none] bg-[transparent] self-stretch relative rounded-[10.24px] box-border h-[47.8px] overflow-hidden shrink-0 border-[0.9px] border-solid border-dimgray-500"
          type="password"
          value={oldPassword}
          placeholder="Enter Your Old Password"
          onChange={(e) => setOldPassword(e.target.value)}
        />
      </div>
      <div className="self-stretch flex flex-col items-start justify-start gap-[3.4px]">
        <div className="relative">New Password</div>
        <input
          className="[outline:none] bg-[transparent] self-stretch relative rounded-[10.24px] box-border h-[47.8px] overflow-hidden shrink-0 border-[0.9px] border-solid border-dimgray-500"
          type="password"
          value={newPassword}
          placeholder="Enter Your New Password"
          onChange={(e) => setNewPassword(e.target.value)}
        />
      </div>
      <div className="self-stretch flex flex-col items-start justify-start gap-[3.4px]">
        <div className="relative">Confirm New Password</div>
        <input
          className="[outline:none] bg-[transparent] self-stretch relative rounded-[10.24px] box-border h-[47.8px] overflow-hidden shrink-0 border-[0.9px] border-solid border-dimgray-500"
          type="password"
          value={confirmPassword}
          placeholder="Confirm Your New Password"
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </div>
      <button
        className="cursor-pointer [border:none] p-[15px] bg-button-blue self-stretch rounded-3xs flex flex-row items-center justify-center"
        onClick={handleSubmit}
      >
        <div className="relative text-xl-2 font-semibold font-inter text-white text-left">
          Reset
        </div>
      </button>
      <button
        className="cursor-pointer p-[15px] bg-white self-stretch rounded-3xs flex flex-row items-center justify-center border-[1px] border-solid border-button-blue"
        onClick={() => dispatch(setSetting("Profile"))}
      >
        <div className="relative text-xl-2 font-semibold font-inter text-button-blue text-left">
          Cancel
        </div>
      </button>
    </div>
  );
};

export default SettingResetPasswordView;
