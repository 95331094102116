import { FunctionComponent, useMemo, useState } from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { CSSProperties } from "react";

export type PasswordEntryType = {
  passwordEntryText?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;

  /** Style props */
  propMinWidth?: CSSProperties["minWidth"];
};

const PasswordEntry: FunctionComponent<PasswordEntryType> = ({
  passwordEntryText,
  value,
  onChange,
  propMinWidth,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const passwordEntryTextStyle: CSSProperties = useMemo(() => {
    return {
      minWidth: propMinWidth,
    };
  }, [propMinWidth]);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <div className="self-stretch flex flex-col items-end justify-start gap-[6.8px] text-left text-sm-7 text-dimgray-200 font-inter">
      <div className="self-stretch relative h-[23px]">
        <div
          className="absolute top-[0px] left-[0px]"
          style={passwordEntryTextStyle}
        >
          {passwordEntryText}
        </div>
      </div>
      <TextField
        className="[border:none] bg-[transparent] self-stretch"
        type={showPassword ? "text" : "password"}
        color="primary"
        variant="outlined"
        sx={{ "& .MuiInputBase-root": { height: "47.8px" } }}
        value={value}
        onChange={onChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default PasswordEntry;
