import React, { useState, useEffect, ChangeEvent } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, TextField, IconButton } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import TopNavBar from "../../components/nav/TopNavBar";
import PatientInfoFormEntryItem from "../../components/patient-info/item/PatientInfoFormEntryItem";
import PatientInfoFormMedicalItem from "../../components/patient-info/item/PatientInfoFormMedicalItem";
import {
  updateUser,
  updateUserById,
} from "../../redux/features/user/userSlice";
import { RootState, AppDispatch } from "../../redux/store";
import { UpdateUserDto, UserRole } from "../../redux/features/user/userTypes";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import logger from "../../util/logger";

const medicalItems = [
  { name: "Arthritis", chineseName: "关节炎" },
  { name: "Cancer", chineseName: "癌症" },
  { name: "Diabetes", chineseName: "糖尿病" },
  { name: "Fracture", chineseName: "骨折" },
  { name: "Gout", chineseName: "痛风" },
  { name: "HIV+", chineseName: "艾滋病" },
  { name: "Hepatitis", chineseName: "肝炎" },
  { name: "Herpes", chineseName: "疱疹" },
  { name: "H.B.P", chineseName: "高血压" },
  { name: "High Cholesterol", chineseName: "高血脂" },
  { name: "L.B.P", chineseName: "低血压" },
  { name: "Measles", chineseName: "麻疹" },
  { name: "Stroke", chineseName: "中风" },
  { name: "Muscle Sprain", chineseName: "肌肉扭伤" },
  { name: "Osteoporosis", chineseName: "骨质疏松" },
  { name: "Cardiovascular", chineseName: "心脏病" },
  { name: "Tuberculosis", chineseName: "结核" },
  { name: "Tumor", chineseName: "肿瘤" },
  { name: "Other", chineseName: "其他" },
];

const PatientInfoFormPage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user.user);

  const currentPatient = useSelector(
    (state: RootState) => state.nav.currentPatient
  );

  const currentUser = user?.role === UserRole.Patient ? user : currentPatient;
  const [formState, setFormState] = useState<UpdateUserDto>({});
  const [isChanged, setIsChanged] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const displayName =
    currentUser?.firstName && currentUser?.lastName
      ? `${currentUser.firstName} ${currentUser.lastName}`
      : currentPatient?.username || "Guest";

  const fullAddress = currentUser?.address
    ? `${currentUser.address.street}, ${currentUser.address.city}, ${currentUser.address.province}, ${currentUser.address.zip}`
    : "";

  logger.log("current Patient" + currentPatient);

  useEffect(() => {
    if (currentUser) {
      setFormState({
        username: currentUser.username,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        phoneNumber: currentUser.phoneNumber,
        address: currentUser.address,
        userMetaData: {
          ...currentUser.userMetaData,
          medicalHistory: currentUser.userMetaData?.medicalHistory || [],
          emergencyContactName:
            currentUser.userMetaData?.emergencyContactName || "",
          emergencyContactPhoneNumber:
            currentUser.userMetaData?.emergencyContactPhoneNumber || "",
          familyDoctorName: currentUser.userMetaData?.familyDoctorName || "",
          familyDoctorPhoneNumber:
            currentUser.userMetaData?.familyDoctorPhoneNumber || "",
        },
        status: currentUser.status,
        balance: currentUser.balance,
      });
      logger.log("formState: ", formState);
      logger.log("birth: ", currentUser.userMetaData?.dateOfBirth);
    }
  }, [currentUser]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    if (
      name === "gender" ||
      name === "familyDoctorName" ||
      name === "familyDoctorPhoneNumber" ||
      name === "emergencyContactName" ||
      name === "emergencyContactPhoneNumber"
    ) {
      setFormState((prevState) => ({
        ...prevState,
        userMetaData: { ...prevState.userMetaData, [name]: value },
      }));
    } else if (name === "msp") {
      setFormState((prevState) => ({
        ...prevState,
        userMetaData: {
          ...prevState.userMetaData,
          [name]: parseInt(value, 10) || 0,
        },
      }));
    } else {
      setFormState((prevState) => ({ ...prevState, [name]: value }));
    }
    setIsChanged(true);
  };

  const handleMedicalHistoryChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormState((prevState) => {
      const medicalHistory = prevState.userMetaData?.medicalHistory || [];
      if (checked) {
        return {
          ...prevState,
          userMetaData: {
            ...prevState.userMetaData,
            medicalHistory: [...medicalHistory, name],
          },
        };
      } else {
        return {
          ...prevState,
          userMetaData: {
            ...prevState.userMetaData,
            medicalHistory: medicalHistory.filter((item) => item !== name),
          },
        };
      }
    });
    setIsChanged(true);
  };

  const handleSave = () => {
    if (user?.role === UserRole.Patient) {
      dispatch(updateUser(formState));
    } else {
      dispatch(
        updateUserById({ userId: currentPatient!.id, userData: formState })
      );
    }
    setIsChanged(false);
  };

  const handleClose = () => {
    navigate(-1);
  };

  const handleDateOfBirthChange = (date: Dayjs | null) => {
    if (date) {
      setFormState((prevState) => ({
        ...prevState,
        userMetaData: { ...prevState.userMetaData, dateOfBirth: date.toDate() },
      }));
      setIsChanged(true);
    }
  };

  if (!currentUser) {
    return null;
  }

  return (
    <div className="w-full relative shadow-[0px_0px_124px_-15px_rgba(0,_0,_0,_0.25)] rounded-8xs bg-background-grey h-[1080px] overflow-hidden flex flex-col items-center justify-start text-left text-xl text-strong font-inter">
      <TopNavBar />
      <div className="self-stretch h-[1015px] overflow-hidden flex flex-col items-center justify-start py-5 px-[30px] box-border">
        <div className="self-stretch flex flex-col items-start justify-start min-w-[1070px]">
          <div className="self-stretch bg-fade-blue flex flex-row items-center justify-center p-5 relative gap-[405px]">
            <div className="relative font-semibold z-[0]">
              Patient Information 病人基本信息
            </div>
            <button
              className={`cursor-pointer p-2.5 !m-[0] absolute top-[10px] right-[20px] shadow-[0px_2.5px_2.52px_rgba(0,_0,_0,_0.25)] rounded-[6.29px] flex flex-row items-center justify-center z-[1] border-[0.6px] border-solid ${
                isChanged
                  ? "bg-button-blue border-button-blue text-neutral-white"
                  : "bg-gray-100 border-gray-100 text-gray-400 cursor-not-allowed"
              }`}
              onClick={handleSave}
              disabled={!isChanged}
            >
              <div className="relative text-lg-9 font-semibold font-inter text-left">
                Save
              </div>
            </button>

            <button
              className="cursor-pointer p-2.5 bg-neutral-white !m-[0] absolute top-[10px] right-[101px] shadow-[0px_2.5px_2.52px_rgba(0,_0,_0,_0.25)] rounded-[6.29px] flex flex-row items-center justify-center z-[2] border-[0.6px] border-solid border-button-blue"
              onClick={handleClose}
            >
              <div className="relative text-lg-9 font-semibold font-inter text-button-blue text-left">
                Close
              </div>
            </button>
          </div>
          <div className="self-stretch flex flex-row items-center h-82 justify-between text-center text-lg-9 ">
            <PatientInfoFormEntryItem
              labelEN="Name"
              lableCN="姓名"
              name="username"
              value={displayName}
              onChange={handleChange}
              disabled
            />
            <div className="flex-1 h-[81px] flex flex-row items-center justify-start min-w-[400px]">
              <div className="self-stretch flex flex-row items-center w-[180px] h-82 justify-center text-center text-lg-9 border-[0.5px] border-solid border-menu-text-gray">
                <div className="">
                  <p className="m-0">Date of Birth</p>
                  <p className="m-0">出生日期</p>
                </div>
              </div>
              <div className="self-stretch flex-1 flex flex-row items-center justify-start p-10 text-left text-base-4 border-[0.5px] border-solid border-menu-text-gray">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    slotProps={{
                      textField: {
                        variant: 'standard',
                        fullWidth: true,
                        InputProps: {
                          disableUnderline: true,
                          classes: {
                            root: "p-2",
                          },
                        },
                      },
                    }}
                    value={dayjs(formState.userMetaData?.dateOfBirth) || null}
                    onChange={handleDateOfBirthChange}
                  />
                </LocalizationProvider>
              </div>
            </div>

            <div className="flex-1 h-[81px] flex flex-row items-center justify-start min-w-[400px]">
              <div className="self-stretch w-[180px] box-border flex flex-col items-center justify-center py-5 px-0 border-[0.5px] border-solid border-menu-text-gray">
                <div className="self-stretch relative">
                  <p className="m-0">Gender</p>
                  <p className="m-0">性别</p>
                </div>
              </div>
              <div className="self-stretch flex-1 flex flex-row items-center justify-start p-5 gap-[10px] text-left text-base border-[0.5px] border-solid border-menu-text-gray">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    className="form-radio"
                    name="gender"
                    value="male"
                    checked={formState.userMetaData?.gender === "male"}
                    onChange={handleChange}
                  />
                  <span className="ml-2">Male</span>
                </label>
                <label className="inline-flex items-center ml-6">
                  <input
                    type="radio"
                    className="form-radio"
                    name="gender"
                    value="female"
                    checked={formState.userMetaData?.gender === "female"}
                    onChange={handleChange}
                  />
                  <span className="ml-2">Female</span>
                </label>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between">
            <PatientInfoFormEntryItem
              labelEN="MSP "
              lableCN="医保号码"
              name="msp"
              value={formState.userMetaData?.msp?.toString() || ""}
              onChange={handleChange}
            />
            <PatientInfoFormEntryItem
              labelEN="Phone"
              lableCN="电话号码"
              name="phoneNumber"
              value={formState.phoneNumber || ""}
              onChange={handleChange}
              disabled
            />
            <PatientInfoFormEntryItem
              labelEN="Balance"
              lableCN="余额"
              name="balance"
              value={formState.balance?.toString() || ""}
              onChange={handleChange}
              disabled
            />
          </div>
          <div className="self-stretch flex flex-row items-center justify-center">
            {/* <div className="self-stretch flex flex-col items-center justify-center py-5"> */}

            <PatientInfoFormEntryItem
              labelEN="Address"
              lableCN="地址"
              name="address"
              value={fullAddress}
              onChange={handleChange}
              disabled
            />
          </div>
          <div className="self-stretch bg-fade-blue flex flex-row items-center justify-center p-5">
            <div className="relative font-semibold">
              Family/Emergency Contact Information 家属/紧急联系人信息
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between">
            <PatientInfoFormEntryItem
              labelEN="Contact Name"
              lableCN="联系人"
              name="emergencyContactName"
              value={formState.userMetaData?.emergencyContactName || ""}
              onChange={handleChange}
            />
            <PatientInfoFormEntryItem
              labelEN="Phone"
              lableCN="电话号码"
              name="emergencyContactPhoneNumber"
              value={formState.userMetaData?.emergencyContactPhoneNumber || ""}
              onChange={handleChange}
            />
          </div>
          <div className="self-stretch flex flex-row items-center justify-between">
            <PatientInfoFormEntryItem
              labelEN="Family Doctor Name"
              lableCN="家庭医生姓名"
              name="familyDoctorName"
              value={formState.userMetaData?.familyDoctorName || ""}
              onChange={handleChange}
            />
            <PatientInfoFormEntryItem
              labelEN="Family Doctor Phone"
              lableCN="家庭医生电话"
              name="familyDoctorPhoneNumber"
              value={formState.userMetaData?.familyDoctorPhoneNumber || ""}
              onChange={handleChange}
            />
          </div>
          <div className="self-stretch bg-fade-blue flex flex-row items-center justify-center p-5">
            <div className="relative font-semibold">
              Patient and Family Medical History 患者及家族病史
            </div>
          </div>
          <div className="self-stretch flex flex-row flex-wrap items-start justify-start border-[0.5px] border-solid border-menu-text-gray">
            {medicalItems.map((item) => (
              <PatientInfoFormMedicalItem
                key={item.name}
                name={item.name}
                chineseName={item.chineseName}
                enabled={
                  formState.userMetaData?.medicalHistory?.includes(item.name) ||
                  false
                }
                onChange={handleMedicalHistoryChange}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientInfoFormPage;
