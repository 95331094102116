import React, { FunctionComponent, useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  setCurrentAppointment,
  setCurrentPatient,
} from "../../redux/features/nav/navSlice";
import { useNavigate } from "react-router-dom";

const InfoDisplayTopView: FunctionComponent = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const onReturnBtnClick = useCallback(() => {
    dispatch(setCurrentPatient(null));
    dispatch(setCurrentAppointment(null));
    window.history.back();
  }, [dispatch, history]);

  const onDetailBtnClick = useCallback(() => {
    navigate("/patient-info-form");
  }, [history]);

  return (
    <div className="self-stretch bg-fade-blue flex flex-row items-center justify-between py-2.5 px-5 z-[0] text-left text-lg-9 text-strong font-inter">
      <div className="w-[172px] relative inline-block shrink-0">
        Patient Name
      </div>
      <div className="flex flex-row gap-[10px]">
        <button
          className="cursor-pointer p-2.5 bg-white shadow-[0px_2.5px_2.52px_rgba(0,_0,_0,_0.25)] rounded-[6.29px] flex flex-row items-center justify-center border-[0.6px] border-solid border-button-blue"
          onClick={onReturnBtnClick}
        >
          <div className="relative text-lg-9 font-semibold font-inter text-button-blue text-left">
            Return
          </div>
        </button>
        <button
          className="cursor-pointer p-2.5 bg-white shadow-[0px_2.5px_2.52px_rgba(0,_0,_0,_0.25)] rounded-[6.29px] flex flex-row items-center justify-center border-[0.6px] border-solid border-button-blue"
          onClick={onDetailBtnClick}
        >
          <div className="relative text-lg-9 font-semibold font-inter text-button-blue text-left">
            Detail
          </div>
        </button>
      </div>
    </div>
  );
};

export default InfoDisplayTopView;
