import { FunctionComponent } from "react";
import { RecordFormAmountItem } from "./RecordFormAmountItem";
import { RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";
import { UserRole } from "../../../../redux/features/user/userTypes";
export type RecordFormInsuranceItemProps = {
  insurance: string;
  coverage: string;
  onInsuranceChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCoverageChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const RecordFormInsuranceItem: React.FC<
  RecordFormInsuranceItemProps
> = ({ insurance, coverage, onInsuranceChange, onCoverageChange }) => {
  const currentUser = useSelector((state: RootState) => state.user.user);
  const disabled = currentUser?.role === UserRole.Patient;
  return (
    <>
      <div className="self-stretch flex flex-row items-center justify-start py-0 px-[30px] box-border gap-[30px] min-w-[600px]">
        <div className="w-[120px] relative inline-block shrink-0">
          Coverage:
        </div>
        <div className="flex flex-row items-start justify-start gap-[48px] text-base">
          <div className="flex flex-row items-center justify-start">
            <input
              className="cursor-pointer m-0 w-6 relative h-6"
              type="radio"
              name="insurance"
              value="MSP"
              checked={insurance === "MSP"}
              onChange={onInsuranceChange}
              disabled={disabled}
            />
            <div className="relative">MSP</div>
          </div>
          <div className="flex flex-row items-center justify-start">
            <input
              className="cursor-pointer m-0 w-6 relative h-6"
              type="radio"
              name="insurance"
              value="ICBC"
              checked={insurance === "ICBC"}
              onChange={onInsuranceChange}
              disabled={disabled}
            />
            <div className="relative">ICBC</div>
          </div>
          <div className="flex flex-row items-center justify-start gap-[5px]">
            <div className="flex flex-row items-center justify-start">
              <input
                className="cursor-pointer m-0 w-6 relative h-6"
                type="radio"
                name="insurance"
                value="Business Insurance"
                checked={insurance.startsWith("Business Insurance")}
                onChange={onInsuranceChange}
                disabled={disabled}
              />
              <div className="relative">Business Insurance</div>
            </div>
            {insurance.startsWith("Business Insurance") && (
              <input
                className="self-stretch w-[186px] rounded-md bg-neutral-white box-border flex flex-row items-center justify-center py-[5px] px-[9px] text-xs border-[1px] border-solid border-text-field-surround-grey"
                type="text"
                name="insuranceCompanyName"
                value={insurance.replace("Business Insurance: ", "")}
                disabled={disabled}
                onChange={(e) =>
                  onInsuranceChange({
                    ...e,
                    target: {
                      ...e.target,
                      value: `Business Insurance: ${e.target.value}`,
                    },
                  })
                }
              />
            )}
          </div>
        </div>
      </div>
      <RecordFormAmountItem
        label="Amount:"
        name="coverage"
        value={coverage}
        onChange={onCoverageChange}
      />
    </>
  );
};
