import { FunctionComponent } from "react";
import { Address } from "../../../redux/features/user/userTypes";

export type AddressProps = {
  address: Address;
  onAddressChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
};

export const AddressViewItem: FunctionComponent<AddressProps> = ({
  address,
  onAddressChange,
}) => {
  const provinces = [
    "Alberta",
    "British Columbia",
    "Manitoba",
    "New Brunswick",
    "Newfoundland and Labrador",
    "Nova Scotia",
    "Ontario",
    "Prince Edward Island",
    "Quebec",
    "Saskatchewan",
  ];

  return (
    <div className="w-full flex flex-col items-start justify-start ]">
      <div className="self-stretch flex flex-col items-start justify-start">
        <div className="self-stretch relative font-semibold">Street</div>
        <input
          className="[outline:none] font-medium font-inter text-mid-3 bg-white self-stretch rounded-8xs flex flex-row items-center justify-start p-[15px] text-gray-100 border-[1px] border-solid border-text-field-surround-grey"
          placeholder="Address"
          type="text"
          name="street"
          value={address.street}
          onChange={onAddressChange}
        />
      </div>

      <div className="self-stretch flex flex-row items-center justify-center gap-[41px] text-left text-lg-7 text-gray-500 font-inter">
        <div className="flex-1 flex flex-col items-start justify-start">
          <div className="self-stretch relative font-semibold">City</div>
          <input
            className="[outline:none] font-medium font-inter text-mid-3 bg-white self-stretch rounded-8xs box-border h-[50px] flex flex-row items-center justify-start py-[7px] px-[15px] text-gray-100 border-[1px] border-solid border-text-field-surround-grey"
            placeholder="City"
            type="text"
            name="city"
            value={address.city}
            onChange={onAddressChange}
          />
        </div>
        <div className="flex-1 flex flex-col items-start justify-start">
          <div className="self-stretch relative font-semibold">Province</div>
          <select
            className="self-stretch rounded-8xs bg-white box-border h-[50px] flex flex-row items-center justify-start py-[7px] px-[15px] text-mid-3 text-gray-100 border-[1px] border-solid border-text-field-surround-grey"
            name="province"
            value={address.province}
            onChange={onAddressChange}
          >
            <option value="" disabled>
              Select a province
            </option>
            {provinces.map((province) => (
              <option key={province} value={province}>
                {province}
              </option>
            ))}
          </select>
        </div>
        <div className="flex-1 flex flex-col items-start justify-start">
          <div className="self-stretch relative font-semibold">Post Code</div>
          <input
            className="[outline:none] font-medium font-inter text-mid-3 bg-white self-stretch rounded-8xs box-border h-[50px] flex flex-row items-center justify-start py-[7px] px-[15px] text-gray-100 border-[1px] border-solid border-text-field-surround-grey"
            placeholder="Post Code"
            type="text"
            name="zip"
            value={address.zip}
            onChange={onAddressChange}
          />
        </div>
      </div>
    </div>
  );
};

export default AddressViewItem;
