import { FunctionComponent, useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../../redux/store";
import RecordTopView from "./RecordTopView";
import { RecordFormTextItem } from "./item/RecordFormTextItem";
import { RecordFormAmountItem } from "./item/RecordFormAmountItem";
import { RecordFormInsuranceItem } from "./item/RecordFormInsuranceItem";
import { RecordType } from "../../../redux/features/record/recordTypes";
import { handleSaveRecord, handleDeleteRecord } from "./record.utils";
import { RecordBottomView } from "./RecordBottomView";
import { UserRole } from "../../../redux/features/user/userTypes";

export type RecordHerbViewType = {
  onClose: () => void;
};

const RecordHerbView: FunctionComponent<RecordHerbViewType> = ({ onClose }) => {
  const dispatch: AppDispatch = useDispatch();
  const currentUser = useSelector((state: RootState) => state.user.user);
  const currentPatient = useSelector(
    (state: RootState) => state.nav.currentPatient
  );
  const currentRecord = useSelector(
    (state: RootState) => state.nav.currentRecord
  );
  const currentAppointment = useSelector(
    (state: RootState) => state.nav.currentAppointment
  );

  const [form, setForm] = useState({
    symptom: "",
    diagnosis: "",
    herbs: "",
    notes: "",
    duration: "",
    isInitial: false,
  });
  const [fee, setFee] = useState("");
  const [showInsuranceFields, setShowInsuranceFields] = useState(
    currentRecord?.insurance ? true : false
  );
  const [insurance, setInsurance] = useState("");
  const [coverage, setCoverage] = useState("");

  const isDisabled = currentUser?.role === UserRole.Patient;

  useEffect(() => {
    if (currentRecord) {
      setForm(currentRecord.form);
      setFee(currentRecord.fee.toString());
      setInsurance(currentRecord.insurance || "");
      setCoverage(currentRecord.coverage?.toString() || "");
    }
  }, [currentRecord]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: checked }));
  };

  const handleFeeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFee(e.target.value);
  };

  const handleInsuranceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInsurance(e.target.value);
  };

  const handleCoverageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCoverage(e.target.value);
  };

  const toggleInsuranceFields = () => {
    setShowInsuranceFields(!showInsuranceFields);
  };

  return (
    <div className="w-[880px] shadow-[0px_0px_4px_2px_rgba(0,_0,_0,_0.1)] rounded-xl bg-white max-w-full overflow-hidden flex flex-col items-start justify-center pt-0 px-0 pb-5 box-border gap-[25px] text-left text-lg-9 text-strong font-inter">
      <RecordTopView
        onClose={onClose}
        onDelete={
          currentRecord
            ? () => handleDeleteRecord(dispatch, currentRecord, onClose)
            : undefined
        }
      />
      <RecordFormTextItem
        label="Symptom:"
        name="symptom"
        value={form.symptom}
        onChange={handleChange}
      />
      <RecordFormTextItem
        label="Diagnosis:"
        name="diagnosis"
        value={form.diagnosis}
        onChange={handleChange}
      />
      <RecordFormTextItem
        label="Herbs:"
        name="herbs"
        value={form.herbs}
        onChange={handleChange}
      />
      <RecordFormTextItem
        label="Notes:"
        name="notes"
        value={form.notes}
        onChange={handleChange}
      />
      <div className="w-full flex flex-row items-center justify-start py-0 px-[30px] box-border gap-[120px] min-w-[750px] max-w-[900px]">
        <div className="flex flex-row items-center justify-start gap-[30px]">
          <div className="w-[120px] relative inline-block shrink-0">{`Duration: `}</div>
          <div className="flex flex-row items-center justify-start gap-[10px]">
            <input
              className="w-[77px] relative rounded-md bg-neutral-white box-border h-[30px] border-[1px] border-solid border-text-field-surround-grey"
              type="text"
              name="duration"
              value={form.duration}
              onChange={handleChange}
              disabled={isDisabled}
            />
            <div className="relative">days</div>
          </div>
        </div>
        <div className="flex flex-row items-center justify-end gap-[30px]">
          <div className="relative">Is Initial:</div>
          <input
            className="m-0 w-6 relative h-6"
            type="checkbox"
            name="isInitial"
            checked={form.isInitial}
            onChange={handleCheckboxChange}
            disabled={isDisabled}
          />
        </div>
      </div>
      <RecordFormAmountItem
        label="Fee:"
        name="fee"
        value={fee}
        onChange={handleFeeChange}
      />
      {showInsuranceFields && (
        <RecordFormInsuranceItem
          insurance={insurance}
          coverage={coverage}
          onInsuranceChange={handleInsuranceChange}
          onCoverageChange={handleCoverageChange}
        />
      )}

      <RecordBottomView
        onClose={onClose}
        onSave={() =>
          handleSaveRecord(
            dispatch,
            currentPatient,
            currentUser,
            currentRecord,
            currentAppointment,
            form,
            fee,
            RecordType.Herb,
            showInsuranceFields,
            insurance,
            coverage,
            onClose
          )
        }
        showInsurance={showInsuranceFields}
        toggleInsurance={toggleInsuranceFields}
        disableSave={
          !form.symptom ||
          !form.diagnosis ||
          !form.herbs ||
          !form.notes ||
          !fee ||
          !form.duration ||
          (showInsuranceFields && (!insurance || !coverage))
        }
        currentRecord={currentRecord}
      />
    </div>
  );
};

export default RecordHerbView;
