import { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  createUser,
  updateUserById,
} from "../../redux/features/user/userSlice";
import { clearCurrentDoctor } from "../../redux/features/nav/navSlice";
import TopNavBar from "../../components/nav/TopNavBar";
import AddressViewItem from "../../components/admin/item/AddressViewItem";
import {
  UserRole,
  CreateUserDto,
  UpdateUserDto,
} from "../../redux/features/user/userTypes";
import { AppDispatch, RootState } from "../../redux/store";

const AddDoctorPage: FunctionComponent = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const currentDoctor = useSelector(
    (state: RootState) => state.nav.currentDoctor
  );

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    title: "",
    phoneNumber: "",
    email: "",
    registrationNumber: "",
    services: {
      acupuncture: false,
      herb: false,
      massage: false,
    },
    description: "",
    address: {
      city: "",
      zip: "",
      province: "",
      street: "",
    },
  });

  useEffect(() => {
    if (currentDoctor) {
      setFormData({
        firstName: currentDoctor.firstName || "",
        lastName: currentDoctor.lastName || "",
        title: currentDoctor.title || "",
        phoneNumber: currentDoctor.phoneNumber || "",
        email: currentDoctor.email || "",
        registrationNumber: currentDoctor.registrationNumber || "",
        services: currentDoctor.services || {
          acupuncture: false,
          herb: false,
          massage: false,
        },
        description: currentDoctor.description || "",
        address: currentDoctor.address || {
          city: "",
          zip: "",
          province: "",
          street: "",
        },
      });
    }
  }, [currentDoctor]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    if (name in formData.address) {
      setFormData((prevState) => ({
        ...prevState,
        address: {
          ...prevState.address,
          [name]: value,
        },
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      services: {
        ...prevState.services,
        [name]: checked,
      },
    }));
  };

  const handleSubmit = async () => {
    if (currentDoctor) {
      const updatedDoctor: UpdateUserDto = {
        username: formData.email,
        firstName: formData.firstName,
        lastName: formData.lastName,
        phoneNumber: formData.phoneNumber,
        address: formData.address,
        title: formData.title,
        registrationNumber: formData.registrationNumber,
        description: formData.description,
        services: formData.services,
      };

      await dispatch(
        updateUserById({ userId: currentDoctor.id, userData: updatedDoctor })
      );
      dispatch(clearCurrentDoctor());
    } else {
      const newDoctor: CreateUserDto = {
        email: formData.email,
        username: formData.email,
        password: "Password123", // Consider changing this in production
        role: UserRole.Doctor,
        firstName: formData.firstName,
        lastName: formData.lastName,
        phoneNumber: formData.phoneNumber,
        registrationNumber: formData.registrationNumber,
        address: formData.address,
        title: formData.title,
        description: formData.description,
        services: formData.services,
      };

      await dispatch(createUser(newDoctor));
    }
    navigate("/doctor-accounts");
  };

  return (
    <div className="w-full relative shadow-[0px_0px_124px_-15px_rgba(0,_0,_0,_0.25)] rounded-8xs bg-background-grey h-[1080px] overflow-hidden flex flex-col items-center justify-start text-left text-lg-7 text-gray-500 font-inter">
      <TopNavBar />
      <div className="self-stretch flex-1 overflow-hidden flex flex-col items-start justify-center py-2.5 px-[100px] max-w-[1080px]">
        <div className="self-stretch flex-1 flex flex-col items-start justify-center py-0 px-5 gap-[10px]">
          <div className="self-stretch flex flex-row items-center justify-between text-29xl">
            <div className="flex-1 relative font-semibold">
              {currentDoctor ? "Update Doctor" : "Add Record"}
            </div>
            <img
              className="w-[65px] relative h-[65px]"
              alt=""
              src="/userprofileimg-1.svg"
            />
          </div>
          <div className="self-stretch flex flex-row items-center justify-start gap-[40px]">
            <div className="flex-1 flex flex-col items-start justify-start max-w-[400px]">
              <div className="self-stretch relative font-semibold">
                First Name
              </div>
              <input
                className="[outline:none] font-medium font-inter text-mid-3 bg-white self-stretch rounded-8xs flex flex-row items-center justify-start p-[15px] text-gray-100 border-[1px] border-solid border-text-field-surround-grey"
                placeholder="First Name"
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
              />
            </div>
            <div className="flex-1 flex flex-col items-start justify-start max-w-[400px]">
              <div className="self-stretch relative font-semibold">
                Last Name
              </div>
              <input
                className="[outline:none] font-medium font-inter text-mid-3 bg-white self-stretch rounded-8xs flex flex-row items-center justify-start p-[15px] text-gray-100 border-[1px] border-solid border-text-field-surround-grey"
                placeholder="Last Name"
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-start gap-[40px]">
            <div className="w-[400px] flex flex-col items-start justify-start max-w-[400px]">
              <div className="self-stretch relative font-semibold">Title</div>
              <select
                className="[outline:none] font-medium font-inter text-mid-3 bg-white self-stretch rounded-8xs flex flex-row items-center justify-start p-[15px] text-gray-100 border-[1px] border-solid border-text-field-surround-grey"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Select Title
                </option>
                <option value="Mr.">Mr.</option>
                <option value="Mrs.">Mrs.</option>
                <option value="Ms.">Ms.</option>
              </select>
            </div>
            <div className="w-[400px] flex flex-col items-start justify-start">
              <div className="self-stretch relative font-semibold">Phone</div>
              <input
                className="[outline:none] bg-white self-stretch rounded-8xs flex flex-row items-center justify-start p-[15px] font-inter font-medium text-mid-3 text-gray-100 border-[1px] border-solid border-text-field-surround-grey"
                type="text"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="w-full flex flex-col items-start justify-start max-w-[840px]">
            <div className="self-stretch relative font-semibold">Email</div>
            <input
              className="[outline:none] font-medium font-inter text-mid-3 bg-white self-stretch rounded-8xs flex flex-row items-center justify-start p-[15px] text-gray-100 border-[1px] border-solid border-text-field-surround-grey"
              placeholder="Email"
              type="text"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              disabled={!!currentDoctor} // Disable email input if updating

            />
          </div>
          <div className="self-stretch flex flex-col items-start justify-start">
            <div className="self-stretch relative font-semibold">{`Registration No. `}</div>
            <input
              className="[outline:none] font-medium font-inter text-mid-3 bg-white w-[400px] rounded-8xs box-border flex flex-row items-center justify-start p-[15px] text-gray-100 border-[1px] border-solid border-text-field-surround-grey"
              placeholder="Registration No."
              type="text"
              name="registrationNumber"
              value={formData.registrationNumber}
              onChange={handleInputChange}
            />
          </div>
          <div className="flex flex-row items-center justify-start py-[15px] px-0 gap-[30px] text-lg-9 text-strong">
            <div className="w-[120px] relative inline-block shrink-0">{`Services: `}</div>
            <div className="flex flex-row items-start justify-start gap-[18px] text-base">
              {["acupuncture", "herb", "massage"].map((service) => (
                <div
                  key={service}
                  className="flex flex-row items-center justify-start gap-[6px]"
                >
                  <input
                    className="m-0 w-6 relative h-6"
                    type="checkbox"
                    name={service}
                    checked={
                      formData.services[
                        service as keyof typeof formData.services
                      ]
                    }
                    onChange={handleCheckboxChange}
                  />
                  <div className="w-[100px] relative inline-block shrink-0">
                    {service.charAt(0).toUpperCase() + service.slice(1)}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="w-full flex flex-col items-start justify-start max-w-[840px]">
            <div className="self-stretch relative font-semibold">Other</div>
            <input
              className="[outline:none] font-medium font-inter text-mid-3 bg-white self-stretch rounded-8xs flex flex-row items-center justify-start p-[15px] text-gray-100 border-[1px] border-solid border-text-field-surround-grey"
              placeholder="Notes"
              type="text"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
            />
          </div>
          <AddressViewItem
            address={formData.address}
            onAddressChange={handleInputChange}
          />
          <div className="self-stretch h-[122px] flex flex-row items-end justify-end gap-[41px] max-w-[840px]">
            <button
              onClick={handleSubmit}
              className="cursor-pointer [border:none] p-[15px] bg-button-blue w-[200px] rounded-3xs flex flex-row items-center justify-center box-border"
            >
              <div className="relative text-xl-2 font-semibold font-inter text-white text-left">
                {currentDoctor ? "Update Doctor" : "Add Record"}
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDoctorPage;
