// src/components/doctor/view/DoctorUpcomingAppointmentView.tsx
import React, { FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../../redux/store";
import {
  updateAppointment,
  deleteAppointment,
} from "../../../redux/features/appointment/appointmentSlice";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Button } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { format } from "date-fns";
import {
  Appointment,
  AppointmentState,
} from "../../../redux/features/appointment/appointmentTypes";
import logger from "../../../util/logger";

const DoctorUpcomingAppointmentView: FunctionComponent = () => {
  const dispatch: AppDispatch = useDispatch();
  const appointments = useSelector(
    (state: RootState) => state.appointment.upcomingAppointments
  );

  logger.log("Appointments:", appointments);

  const handleAccept = (id: number) => {
    dispatch(
      updateAppointment({
        appointmentId: id,
        updateData: { state: AppointmentState.Scheduled },
      })
    );
  };

  const handleCancel = (id: number) => {
    dispatch(deleteAppointment(id));
  };

  const ActionCellRenderer: React.FC<ICellRendererParams> = (params) => {
    const appointment = params.data;
    return appointment.state === AppointmentState.Pending ? (
      <div style={{ display: "flex", gap: "10px" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleAccept(appointment.id)}
        >
          Accept
        </Button>
        <Button
          variant="contained"
          style={{ backgroundColor: "#E92F2F", color: "white" }}
          onClick={() => handleCancel(appointment.id)}
        >
          Cancel
        </Button>
      </div>
    ) : (
      <Button
        variant="contained"
        style={{ backgroundColor: "#E92F2F", color: "white" }}
        onClick={() => handleCancel(appointment.id)}
      >
        Cancel
      </Button>
    );
  };

  const columnDefs: ColDef[] = [
    {
      headerName: "Name",
      field: "participants[0].firstName", // Assuming the first participant is the patient
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => {
        const participant = params.data.participants[0];
        return participant.firstName && participant.lastName
          ? `${participant.firstName} ${participant.lastName}`
          : `${participant.username}`;
      },
    },
    {
      headerName: "Phone",
      field: "participants[0].phoneNumber", // Change to patient's phone number
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => {
        logger.log("Phone number:", params.data.participants[0].phoneNumber); // Debugging line
        return params.data.participants[0].phoneNumber;
      },
    },
    {
      headerName: "Appointment Time",
      field: "timeFrom",
      flex: 2,
      minWidth: 200,
      valueFormatter: (params) =>
        format(new Date(params.value), "MMM dd, yyyy, hh:mm a"),
    },
    { headerName: "Status", field: "state", flex: 1, minWidth: 150 },
    {
      headerName: "Action",
      field: "id",
      flex: 1,
      minWidth: 200,
      cellRenderer: ActionCellRenderer,
    },
  ];

  return (
    <div className="w-full">
      <h1 className="m-0 text-inherit tracking-[-0.01em] leading-[20px] font-bold font-inherit max-w-full text-left mq450:text-3xl mq450:leading-[27px] pb-10">
        Upcoming Appointments
      </h1>
      <div className="ag-theme-alpine" style={{ height: 400, width: "100%" }}>
        <AgGridReact
          rowData={appointments}
          columnDefs={columnDefs}
          domLayout="autoHeight"
        />
      </div>
    </div>
  );
};

export default DoctorUpcomingAppointmentView;
