import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField, Button, MenuItem, Autocomplete } from "@mui/material";
import { SchedulerHelpers } from "@aldabil/react-scheduler/types";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  AppointmentState,
  CreateAppointmentDto,
} from "../../../redux/features/appointment/appointmentTypes";
import {
  createAppointment,
  updateAppointment,
} from "../../../redux/features/appointment/appointmentSlice";
import { User } from "../../../redux/features/user/userTypes";

const toLocalISOString = (date: Date) => {
  const offset = date.getTimezoneOffset();
  const adjustedDate = new Date(date.getTime() - offset * 60 * 1000);
  return adjustedDate.toISOString().slice(0, 16);
};

const AppointmentEditorView: FunctionComponent<{
  scheduler: SchedulerHelpers;
}> = ({ scheduler }) => {
  const dispatch: AppDispatch = useDispatch();
  const patients = useSelector((state: RootState) => state.user.patients || []);

  const [state, setState] = useState({
    description: scheduler.state.title?.value || "",
    appointmentState: scheduler.edited
      ? scheduler.edited.state
      : AppointmentState.Open,
    start: scheduler.state.start.value || new Date(),
    end: scheduler.state.end.value || new Date(),
    participant: scheduler.edited
      ? scheduler.edited.participant
      : (null as User | null),
  });

  const [descriptionError, setDescriptionError] = useState("");
  const [timeError, setTimeError] = useState("");
  const [participantError, setParticipantError] = useState("");

  const handleChange = (value: any, name: string) => {
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    let hasError = false;

    if (state.description.length < 3) {
      setDescriptionError("Description must be at least 3 characters long");
      hasError = true;
    } else {
      setDescriptionError("");
    }

    if (state.start > state.end) {
      setTimeError("Start time cannot be later than end time");
      hasError = true;
    } else {
      setTimeError("");
    }

    if (
      state.appointmentState === AppointmentState.Scheduled &&
      !state.participant
    ) {
      setParticipantError(
        "Participant must be selected for scheduled appointments"
      );
      hasError = true;
    } else {
      setParticipantError("");
    }

    if (hasError) return;

    const appointment: CreateAppointmentDto = {
      timeFrom: state.start,
      timeTo: state.end,
      description: state.description,
      state: state.appointmentState,
      participants:
        state.appointmentState === AppointmentState.Scheduled
          ? [state.participant!.id]
          : [],
    };

    try {
      scheduler.loading(true);
      if (scheduler.state.event_id.value) {
        await dispatch(
          updateAppointment({
            appointmentId: scheduler.state.event_id.value,
            updateData: appointment,
          })
        );
      } else {
        await dispatch(createAppointment(appointment));
      }
      scheduler.close();
    } finally {
      scheduler.loading(false);
    }
  };

  return (
    <form className="m-0 w-[678px] rounded-xl bg-neutral-white max-w-full overflow-hidden flex flex-col items-center justify-start pt-0 px-0 pb-5 box-border gap-[25px] tracking-[normal] leading-[normal]">
      <div className="self-stretch h-[66px] bg-[transparent] box-border flex flex-row items-center justify-between p-5 font-inter font-bold text-xl text-neutral-black-300 min-w-[400px] border-b-[1px] border-solid border-menu-line-grey" />
      <div className="self-stretch flex flex-row items-center justify-center py-0 px-[30px] box-border max-w-full mq450:gap-[20px]">
        <div className="flex-1 flex flex-col items-start justify-start max-w-full">
          <TextField
            label="Start Date"
            type="datetime-local"
            value={toLocalISOString(state.start)}
            onChange={(e) => {
              try {
                toLocalISOString(new Date(e.target.value));
                return handleChange(new Date(e.target.value), "start");
              } catch {
                return;
              }
            }}
            fullWidth
            InputLabelProps={{ shrink: true }}
            error={!!timeError}
            helperText={timeError}
            onInput={(e) => {}}
          />
        </div>
        <div className="flex-1 flex flex-col items-start justify-start max-w-full">
          <TextField
            label="End Date"
            type="datetime-local"
            value={toLocalISOString(state.end)}
            onChange={(e) => {
              try {
                toLocalISOString(new Date(e.target.value));
                return handleChange(new Date(e.target.value), "end");
              } catch {
                return;
              }
            }}
            fullWidth
            InputLabelProps={{ shrink: true }}
            error={!!timeError}
            helperText={timeError}
          />
        </div>
      </div>
      <div className="self-stretch flex flex-row items-center justify-center py-0 px-[30px] box-border max-w-full mq450:gap-[20px]">
        <div className="flex-1 flex flex-col items-start justify-start max-w-full">
          <TextField
            label="Purpose"
            value={state.description}
            onChange={(e) => handleChange(e.target.value, "description")}
            error={!!descriptionError}
            helperText={descriptionError}
            fullWidth
          />
        </div>
      </div>
      <div className="self-stretch flex flex-row items-center justify-center py-0 px-[30px] box-border max-w-full mq450:gap-[20px]">
        <div className="flex-1 flex flex-col items-start justify-start max-w-full">
          <TextField
            select
            label="State"
            value={state.appointmentState}
            onChange={(e) => handleChange(e.target.value, "appointmentState")}
            fullWidth
          >
            <MenuItem value={AppointmentState.Open}>Open</MenuItem>
            <MenuItem value={AppointmentState.Scheduled}>Scheduled</MenuItem>
            <MenuItem value={AppointmentState.NotAvailable}>
              Not Available
            </MenuItem>
          </TextField>
        </div>
      </div>
      {state.appointmentState === AppointmentState.Scheduled && (
        <div className="self-stretch flex flex-row items-center justify-center py-0 px-[30px] box-border max-w-full mq450:gap-[20px]">
          <div className="flex-1 flex flex-col items-start justify-start max-w-full">
            <Autocomplete
              options={patients}
              getOptionLabel={(option: User) =>
                `${option.firstName ?? ""} ${option.lastName ?? ""} ${
                  option.email
                }`
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Patient"
                  fullWidth
                  error={!!participantError}
                  helperText={participantError}
                />
              )}
              onChange={(event, newValue) => {
                handleChange(newValue, "participant");
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={state.participant}
              fullWidth
              sx={{
                "& .MuiAutocomplete-inputRoot": {
                  padding: "0 8px",
                },
                "& .MuiAutocomplete-option": {
                  padding: "8px",
                },
                "& .MuiAutocomplete-listbox": {
                  maxHeight: "200px",
                },
              }}
            />
          </div>
        </div>
      )}
      <div className="self-stretch flex flex-row items-center justify-between py-0 px-[30px] gap-[20px] mq450:flex-wrap">
        <Button
          className="cursor-pointer py-[13px] pr-[25px] pl-6 bg-[transparent] w-[100px] rounded-3xs box-border flex items-center justify-center border-[1px] border-solid border-cancel-red hover:bg-tomato-200 hover:box-border hover:border-[1px] hover:border-solid hover:border-tomato-100"
          onClick={scheduler.close}
        >
          <b className="relative text-sm tracking-[0.2px] leading-[18px] inline-block font-inter text-cancel-red text-center">
            Cancel
          </b>
        </Button>
        <Button
          style={{ color: "white", backgroundColor: "#3b82f6" }}
          className="cursor-pointer [border:none] py-[15px] pr-[18px] pl-[25px] rounded-3xs flex items-center justify-center hover:bg-dodgerblue"
          onClick={handleSubmit}
        >
          <b className="relative text-sm tracking-[0.2px] leading-[18px] inline-block font-inter text-center">
            Confirm
          </b>
        </Button>
      </div>
    </form>
  );
};

export default AppointmentEditorView;
