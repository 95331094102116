import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import TopNavBar from "../../components/nav/TopNavBar";
import AdminDoctorAccountsTableView from "../../components/admin/view/AdminDoctorAccountsTableView";

const AdminDoctorAccountsPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const doctorCount = useSelector(
    (state: RootState) => state.user.doctors.length
  );
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div className="w-full relative shadow-[0px_0px_124px_-15px_rgba(0,_0,_0,_0.25)] rounded-8xs bg-background-grey h-[1080px] overflow-hidden flex flex-col items-center justify-start text-left text-lg text-gray-400 font-inter">
      <TopNavBar />
      <div className="self-stretch flex-1 overflow-hidden flex flex-col items-center justify-start p-10 gap-[30px]">
        <div className="self-stretch bg-background-grey flex flex-row items-center justify-between py-0 pr-2.5 pl-[30px] box-border min-w-[580px]">
          <div className="h-[50px] flex flex-col items-start justify-start py-0 px-2.5 box-border gap-[8px]">
            <div className="relative leading-[21px] font-medium">
              List of Doctors
            </div>
            <div className="flex flex-row items-center justify-center gap-[4px] text-xs text-silver-200">
              <div className="relative leading-[21px] font-medium">
                {doctorCount}
              </div>
              <div className="relative leading-[21px] font-medium">
                Doctor Accounts
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center gap-[20px]">
            <div className="relative [border:none] bg-white w-[350px] rounded-81xl overflow-hidden shrink-0 flex flex-row items-center justify-between box-border">
              <input
                className="[outline:none] font-inter text-smi py-3 px-4 text-text-field-surround-grey [border:none] w-full h-full [background:transparent] relative z-[3] ml-[46px]"
                placeholder="Search"
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <img
                className="w-[4%] absolute h-3.5 overflow-hidden shrink-0 z-[1] left-[16px] top-[13px] [pointer-events:none]"
                alt=""
                src="/icon--icofont--web--search--default.svg"
              />
            </div>
            <button
              className="cursor-pointer p-[15px] bg-button-blue text-white rounded-3xs"
              onClick={() => navigate("/add-doctor")}
            >
              Add Doctor
            </button>
          </div>
        </div>
        <AdminDoctorAccountsTableView searchQuery={searchQuery} />
      </div>
    </div>
  );
};

export default AdminDoctorAccountsPage;
