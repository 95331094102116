// src/pages/PatientInfoPage.tsx
import React, { FunctionComponent, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import TopNavBar from "../../components/nav/TopNavBar";
import InfoDisplayTopView from "../../components/patient-info/PatientInfoTopView";
import PatientInfoRecordView from "../../components/patient-info/PatientInfoRecordView";
import RecordPopup from "../../components/patient-info/record/RecordPopup";
import { setCurrentRecord } from "../../redux/features/nav/navSlice";
import { UserRole, DoctorService } from "../../redux/features/user/userTypes";

const PatientInfoPage: FunctionComponent = () => {
  const dispatch: AppDispatch = useDispatch();
  const currentPatient = useSelector(
    (state: RootState) => state.nav.currentPatient
  );
  const currentUser = useSelector((state: RootState) => state.user.user);

  const [showPopup, setShowPopup] = useState<string | null>(null);
  const [doctorServices, setDoctorServices] = useState<DoctorService | null>(
    null
  );

  const displayName =
    currentPatient?.firstName && currentPatient?.lastName
      ? `${currentPatient.firstName} ${currentPatient.lastName}`
      : currentPatient?.username || "Guest";

  useEffect(() => {
    if (currentUser && currentUser.role === UserRole.Doctor) {
      setDoctorServices(currentUser.services);
    }
  }, [currentUser]);

  const openPopup = (type: string) => {
    setShowPopup(type);
  };

  const closePopup = () => {
    dispatch(setCurrentRecord(null));
    setShowPopup(null);
  };

  return (
    <>
      <div className="w-full relative shadow-[0px_0px_124px_-15px_rgba(0,_0,_0,_0.25)] rounded-8xs bg-background-grey h-[1080px] overflow-hidden flex flex-col items-center justify-start text-left text-lg-9 text-strong font-inter">
        <TopNavBar />
        <div className="self-stretch flex-1 overflow-hidden flex flex-col items-center justify-start py-5 px-[30px] box-border gap-[10px] min-w-[600px]">
          <div className="self-stretch flex flex-col items-start justify-start relative gap-[10px]">
            <InfoDisplayTopView />
            <div className="self-stretch flex flex-row items-center justify-between py-2.5 px-5 z-[1]">
              <div className="flex-1 flex flex-col items-start justify-center gap-[2px] min-w-[170px]">
                <div className="self-stretch flex flex-col items-start justify-start">
                  <div className="relative">Name</div>
                </div>
                <div className="self-stretch relative text-base-4">
                  {displayName}
                </div>
              </div>
              <div className="flex-1 flex flex-col items-start justify-start gap-[2px] min-w-[170px]">
                <div className="self-stretch relative">Date of Birth</div>
                <div className="self-stretch relative text-base-4">
                  {currentPatient?.userMetaData?.dateOfBirth
                    ? new Date(
                        currentPatient?.userMetaData?.dateOfBirth
                      ).toLocaleDateString()
                    : "##/##/####"}
                </div>
              </div>
              <div className="flex-1 flex flex-col items-start justify-start min-w-[170px]">
                <div className="self-stretch relative">Gender</div>
                <div className="self-stretch relative text-base-4">
                  {currentPatient?.userMetaData?.gender || "N/A"}
                </div>
              </div>
            </div>
            <div className="self-stretch relative bg-silver-300 box-border h-[0.6px] z-[2] border-[0.5px] border-solid border-button-gray" />
            <div className="self-stretch flex flex-row items-start justify-between py-2.5 px-5 z-[3]">
              <div className="flex-1 flex flex-col items-start justify-start min-w-[170px]">
                <div className="self-stretch relative">MSP:</div>
                <div className="self-stretch relative text-base-4">
                  {currentPatient?.userMetaData?.msp || "###-###-####"}
                </div>
              </div>
              <div className="flex-1 flex flex-col items-start justify-start gap-[1px] min-w-[170px]">
                <div className="self-stretch relative">Phone</div>
                <div className="self-stretch relative text-base-4">
                  {currentPatient?.phoneNumber || "###-###-####"}
                </div>
              </div>
              <div className="flex-1 flex flex-col items-start justify-start gap-[2px] min-w-[170px]">
                <div className="self-stretch relative">Balance</div>
                <div className="self-stretch relative text-base-4">
                  {currentPatient?.balance || "###.##"}
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row flex-wrap items-start justify-center p-5 gap-[20px]">
            {doctorServices?.acupuncture && (
              <button
                className="cursor-pointer [border:none] p-2.5 bg-button-blue w-[200px] rounded-8xs flex flex-row items-center justify-center box-border"
                onClick={() => openPopup("Acupuncture")}
              >
                <div className="relative text-lg-9 font-semibold font-inter text-white text-left">
                  Acupuncture
                </div>
              </button>
            )}
            {doctorServices?.herb && (
              <button
                className="cursor-pointer [border:none] p-2.5 bg-button-blue w-[200px] rounded-8xs flex flex-row items-center justify-center box-border"
                onClick={() => openPopup("Herb")}
              >
                <div className="relative text-lg-9 font-semibold font-inter text-white text-left">
                  Chinese Herb
                </div>
              </button>
            )}
            <button
              className="cursor-pointer [border:none] p-2.5 bg-button-blue w-[200px] rounded-8xs flex flex-row items-center justify-center box-border"
              onClick={() => openPopup("Consulting")}
            >
              <div className="relative text-lg-9 font-semibold font-inter text-white text-left">
                Consulting
              </div>
            </button>
            <button
              className="cursor-pointer [border:none] p-2.5 bg-button-blue w-[200px] rounded-8xs flex flex-row items-center justify-center box-border"
              onClick={() => openPopup("Other")}
            >
              <div className="relative text-lg-9 font-semibold font-inter text-white text-left">
                Other
              </div>
            </button>
            <button
              className="cursor-pointer [border:none] p-2.5 bg-button-blue w-[200px] rounded-8xs flex flex-row items-center justify-center box-border"
              onClick={() => openPopup("LoadBalance")}
            >
              <div className="relative text-lg-9 font-semibold font-inter text-white text-left">
                Load Balance
              </div>
            </button>
            <button className="cursor-pointer [border:none] p-2.5 bg-button-blue w-[200px] rounded-8xs flex flex-row items-center justify-center box-border">
              <div className="relative text-lg-9 font-semibold font-inter text-white text-left">
                Statement
              </div>
            </button>
          </div>
          <PatientInfoRecordView openRecordView={openPopup} />
        </div>
      </div>
      <RecordPopup type={showPopup} onClose={closePopup} />
    </>
  );
};

export default PatientInfoPage;
