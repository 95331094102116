// src/components/doctor/view/DoctorDashboardNavBar.tsx

import { FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { setDashboard } from "../../../redux/features/nav/navSlice";

export type DoctorDashboardNavBarType = {
  className?: string;
};

const DoctorDashboardNavBar: FunctionComponent<DoctorDashboardNavBarType> = ({
  className = "",
}) => {
  const dispatch = useDispatch();
  const currentView = useSelector((state: RootState) => state.nav.dashboard);
  const todayAppointmentsCount = useSelector(
    (state: RootState) => state.appointment.todayAppointments.length
  );
  const upcomingAppointmentsCount = useSelector(
    (state: RootState) => state.appointment.upcomingAppointments.length
  );
  const appointmentsThisWeekCount = useSelector(
    (state: RootState) => state.appointment.appointmentThisWeek.length
  );
  const appointmentsHistoryCount = useSelector(
    (state: RootState) => state.appointment.appointmentsHistory.length
  );
  const handleButtonClick = (
    view:
      | "BookAppointment"
      | "UpcomingAppointment"
      | "AppointmentHistory"
      | "AppointmentAvailable"
      | "TodayAppointment"
      | "Calendar"
  ) => {
    dispatch(setDashboard(view));
  };

  const buttonHeightClass = "h-[140px]";

  return (
    <div
      className={`w-full max-w-full flex flex-row items-center justify-between p-2 box-border gap-[20px] ${className}`}
    >
      <button
        className={`cursor-pointer [border:none] p-4 ${
          currentView === "TodayAppointment" ? "bg-fill" : "bg-neutral-white"
        } ${buttonHeightClass} flex-1 rounded-xl flex flex-col items-center justify-between box-border min-w-[320px] max-w-[400px]`}
        onClick={() => handleButtonClick("TodayAppointment")}
      >
        <div className="flex flex-col items-center justify-center gap-[4px]">
          <div className="flex flex-row items-center justify-center gap-[4px]">
            <img className="w-10 h-10" alt="" src="/treamenticon.svg" />
            <div className="text-base font-medium font-inter text-gray-300">
              Today’s Appointments
            </div>
          </div>
          <div className="text-center w-full mt-2">
            <b className="text-6xl font-inter text-gray-300">
              <p className="m-0">{todayAppointmentsCount} Appointments</p>
              <p className="m-0">Today</p>
            </b>
          </div>
        </div>
      </button>
      <button
        className={`cursor-pointer [border:none] p-4 ${
          currentView === "UpcomingAppointment" ? "bg-fill" : "bg-neutral-white"
        } ${buttonHeightClass} flex-1 rounded-xl flex flex-col items-center justify-between box-border min-w-[320px] max-w-[400px]`}
        onClick={() => handleButtonClick("UpcomingAppointment")}
      >
        <div className="flex flex-col items-center justify-center gap-[4px]">
          <div className="flex flex-row items-center justify-center gap-[4px]">
            <img className="w-10 h-10" alt="" src="/treamenticon-1.svg" />
            <div className="text-base font-medium font-inter text-gray-300">
              Upcoming Appointments
            </div>
          </div>
          <div className="text-center w-full mt-2">
            <b className="text-6xl font-inter text-gray-300">
              <p className="m-0">{upcomingAppointmentsCount} Appointments</p>
              <p className="m-0">For Approval</p>
            </b>
          </div>
        </div>
      </button>
      <button
        className={`cursor-pointer [border:none] p-4 ${
          currentView === "Calendar" ? "bg-fill" : "bg-neutral-white"
        } ${buttonHeightClass} flex-1 rounded-xl flex flex-col items-center justify-between box-border min-w-[320px] max-w-[400px]`}
        onClick={() => handleButtonClick("Calendar")}
      >
        <div className="flex flex-col items-center justify-center gap-[4px]">
          <div className="flex flex-row items-center justify-center gap-[4px]">
            <img className="w-10 h-10" alt="" src="/treamenticon-2.svg" />
            <div className="text-base font-medium font-inter text-gray-300">
              Calendar
            </div>
          </div>
          <div className="text-center w-full mt-2">
            <b className="text-6xl font-inter text-gray-300">
              <p className="m-0">{appointmentsThisWeekCount} Appointments</p>
              <p className="m-0">This Week</p>
            </b>
          </div>
        </div>
      </button>
      <button
        className={`cursor-pointer [border:none] p-4 ${
          currentView === "AppointmentHistory" ? "bg-fill" : "bg-neutral-white"
        } ${buttonHeightClass} flex-1 rounded-xl flex flex-col items-center justify-between box-border min-w-[320px] max-w-[400px]`}
        onClick={() => handleButtonClick("AppointmentHistory")}
      >
        <div className="flex flex-col items-center justify-center gap-[4px]">
          <div className="flex flex-row items-center justify-center gap-[4px]">
            <img className="w-10 h-10" alt="" src="/treamenticon-3.svg" />
            <div className="text-base font-medium font-inter text-gray-300">
              Appointment History
            </div>
          </div>
          <div className="text-center w-full mt-2">
            <b className="text-6xl font-inter text-gray-300">
              <p className="m-0">{appointmentsHistoryCount} Appointments</p>
              <p className="m-0">Last Week</p>
            </b>
          </div>
        </div>
      </button>
    </div>
  );
};

export default DoctorDashboardNavBar;
