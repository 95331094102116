import { FunctionComponent } from "react";
import { GroupByPeriod } from "../../../../redux/features/record/recordTypes";

type StatisticHeaderProps = {
  groupBy: GroupByPeriod;
  setGroupBy: (groupBy: GroupByPeriod) => void;
  onFilterClick: () => void;
};

const StatisticHeader: FunctionComponent<StatisticHeaderProps> = ({
  groupBy,
  setGroupBy,
  onFilterClick,
}) => {
  return (
    <div className="self-stretch flex-1 rounded-8xs bg-white flex flex-row items-center justify-between py-2.5 px-5 box-border max-h-[45px] text-xs text-silver-200">
      <div className="flex flex-row items-center justify-center gap-[4px]">
        <div className="relative leading-[21px] font-medium">115</div>
        <b className="relative leading-[21px]">{`items showing `}</b>
      </div>
      <div className="flex flex-row items-center justify-start gap-[5px] text-sm text-gray-300">
        {[
          { label: "Day", value: GroupByPeriod.Day },
          { label: "Week", value: GroupByPeriod.Week },
          { label: "Month", value: GroupByPeriod.Month },
          { label: "Year", value: GroupByPeriod.Year },
        ].map(({ label, value }) => (
          <div
            key={label}
            className={`w-[75px] rounded-8xs flex flex-row items-center justify-center py-2.5 px-5 box-border ${
              groupBy === value
                ? "bg-white border-[1px] border-solid border-menu-line-grey"
                : "text-button-gray"
            } cursor-pointer`}
            onClick={() => setGroupBy(value)}
          >
            <b className="relative leading-[120%]">{label}</b>
          </div>
        ))}
        <div
          className="w-[75px] rounded-8xs bg-white box-border flex flex-row items-center justify-center py-2.5 px-5 border-[1px] border-solid border-menu-line-grey cursor-pointer"
          onClick={onFilterClick}
        >
          <b className="w-[34px] relative leading-[120%] inline-block shrink-0">
            Filter
          </b>
        </div>
      </div>
    </div>
  );
};

export default StatisticHeader;
