// src/components/doctor/view/DoctorTodayAppointmentView.tsx
import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import DoctorAppointmentDisplayItem from "../item/DoctorAppointmentDisplayItem";
import { isToday } from "date-fns";
import { Appointment } from "../../../redux/features/appointment/appointmentTypes";

const DoctorTodayAppointmentView: FunctionComponent = () => {
  const todayAppointments = useSelector(
    (state: RootState) => state.appointment.todayAppointments
  );

  return (
    <div className="w-full flex flex-col items-start px-5">
      <div className="w-full flex flex-row items-center justify-start py-2.5 box-border min-w-[1100px] max-w-[1450px]">
        <h2 className="m-0 w-full text-inherit tracking-[-0.01em] leading-[20px] font-bold font-inherit max-w-full text-left mq450:text-3xl mq450:leading-[27px]">
          Today’s Appointments
        </h2>
      </div>
      <section className="w-full grid grid-cols-5 gap-[20px] p-5 box-border">
        {todayAppointments.map((appointment: Appointment) => (
          <DoctorAppointmentDisplayItem
            key={appointment.id}
            appointment={appointment}
          />
        ))}
      </section>
    </div>
  );
};

export default DoctorTodayAppointmentView;
