// src/components/UpcomingAppointmentView.tsx
import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import PatientAppointmentDisplayItem from "../item/PatientAppointmentDisplayItem";
import { Appointment } from "../../../redux/features/appointment/appointmentTypes";
import logger from "../../../util/logger";

const PatientUpcomingAppointmentView: FunctionComponent = () => {
  logger.log("PatientUpcomingAppointmentView");
  const appointments = useSelector((state: RootState) =>
    state.appointment.appointments.filter(
      (appointment: Appointment) => new Date(appointment.timeTo) > new Date()
    )
  );
  const existingAppointments = useSelector(
    (state: RootState) => state.appointment.appointments
  );
  logger.log("appointments", existingAppointments);
  return (
    <div className="w-full flex flex-col items-start px-5">
      <div className="w-full flex flex-row items-center justify-start py-2.5 box-border min-w-[1100px] max-w-[1450px]">
        <h2 className="m-0 w-full text-inherit tracking-[-0.01em] leading-[20px] font-bold font-inherit max-w-full text-left mq450:text-3xl mq450:leading-[27px]">
          Upcoming Appointments
        </h2>
      </div>
      <section className="self-stretch flex flex-row flex-wrap items-start justify-start py-0 gap-[40px] text-left text-xs-9 text-neutral-gray-dark font-inter mt-4">
        {appointments.map((appointment: Appointment) => (
          <div
            key={appointment.id}
            className="bg-white p-4 shadow-md rounded-lg min-w-[300px] max-w-[400px] w-full"
          >
            <PatientAppointmentDisplayItem
              {...appointment}
              name={`${appointment.host?.firstName} ${appointment.host?.lastName}`}
            />
          </div>
        ))}
      </section>
    </div>
  );
};

export default PatientUpcomingAppointmentView;
