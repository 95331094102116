import { FunctionComponent, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import TopNavBar from "../../components/nav/TopNavBar";
import DoctorDashboardNavBar from "../../components/doctor/nav/DoctorDashboardNavBar";
import DoctorTodayAppointmentView from "../../components/doctor/view/DoctorTodayAppointmentView";
import UpcomingAppointmentView from "../../components/doctor/view/DoctorUpcomingAppointmentView";
import DoctorCalendarView from "../../components/doctor/view/DoctorCalendarView";
import DoctorAppointmentHistoryView from "../../components/doctor/view/DoctorAppointmentHistoryView";
import { fetchAppointments } from "../../redux/features/appointment/appointmentSlice";
import { GetAppointmentsDto } from "../../redux/features/appointment/appointmentTypes";

const DoctorDashboardPage: FunctionComponent = () => {
  const dispatch: AppDispatch = useDispatch();
  const currentView =
    useSelector((state: RootState) => state.nav.dashboard) ||
    "TodayAppointment";
  const { user } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (user) {
      const getAppointmentsDto: GetAppointmentsDto = { userType: "all" };
      dispatch(fetchAppointments(getAppointmentsDto));
    }
  }, [dispatch, user]);

  const renderView = () => {
    switch (currentView) {
      case "TodayAppointment":
        return <DoctorTodayAppointmentView />;
      case "UpcomingAppointment":
        return <UpcomingAppointmentView />;
      case "Calendar":
        return <DoctorCalendarView />;
      case "AppointmentHistory":
        return <DoctorAppointmentHistoryView />;
      default:
        return <DoctorTodayAppointmentView />;
    }
  };

  return (
    <div className="w-full relative shadow-[0px_0px_124px_-15px_rgba(0,_0,_0,_0.25)] rounded-8xs bg-background-grey overflow-hidden flex flex-col items-center justify-start pt-0 px-0 pb-[114px] box-border min-w-[1440px] tracking-[normal] leading-[normal]">
      <TopNavBar />
      <main className="flex-grow overflow-auto flex flex-col items-center justify-start pt-[30px] px-10 box-border gap-[40px] max-w-full text-left text-9xl text-gray-300 font-inter">
        <DoctorDashboardNavBar />
        {renderView()}
      </main>
    </div>
  );
};

export default DoctorDashboardPage;
