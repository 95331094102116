// src/components/UpcomingAppointmentItem.tsx
import { FunctionComponent, useState } from "react";
import PatientCancelAppointmentView from "./PatientCancelAppointmentView";
import PatientCancelAppointmentFailedView from "./PatientCancelAppointmentFailedView";
import PortalPopup from "../../patient-info/record/PortalPopup";

interface PatientAppointmentDisplayItemProps {
  id: number;
  name: string;
  timeFrom: Date;
  timeTo: Date;
  state: string;
}

const PatientAppointmentDisplayItem: FunctionComponent<
  PatientAppointmentDisplayItemProps
> = ({ id, name, timeFrom, timeTo, state }) => {
  const [showPopup, setShowPopup] = useState<string | null>(null);

  const handleCancelClick = () => {
    const now = new Date();
    const appointmentTime = new Date(timeFrom);
    const timeDifference = appointmentTime.getTime() - now.getTime();

    if (timeDifference > 24 * 60 * 60 * 1000) {
      setShowPopup("cancel");
    } else {
      setShowPopup("failed");
    }
  };

  const closePopup = () => {
    setShowPopup(null);
  };

  return (
    <div className="h-[155px] flex-1 rounded-xl bg-white flex flex-col items-start justify-between p-5 box-border min-w-[225px]">
      <div className="flex flex-row items-center justify-start text-mini">
        <b className="relative tracking-[0.09px] leading-[18px] inline-block min-w-[116px]">
          {name}
        </b>
      </div>
      <div className="relative tracking-[0.09px] leading-[18px] whitespace-nowrap">
        {new Date(timeFrom).toLocaleString()}
      </div>
      <div className="relative tracking-[0.09px] leading-[18px]">{`Appointment Status: ${state}`}</div>
      <b
        className="relative [text-decoration:underline] tracking-[0.09px] leading-[18px] text-neutral-black cursor-pointer"
        onClick={handleCancelClick}
      >
        Cancel Appointment
      </b>
      {showPopup === "cancel" && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closePopup}
        >
          <PatientCancelAppointmentView
            appointmentId={id}
            onClose={closePopup}
          />
        </PortalPopup>
      )}
      {showPopup === "failed" && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closePopup}
        >
          <PatientCancelAppointmentFailedView onClose={closePopup} />
        </PortalPopup>
      )}
    </div>
  );
};

export default PatientAppointmentDisplayItem;
