import { FunctionComponent } from "react";

export type TopViewType = {
  className?: string;
  newAppointmentTitle?: string;
};

const PatientItemTopView: FunctionComponent<TopViewType> = ({
  className = "",
  newAppointmentTitle,
}) => {
  return (
    <div
      className={`self-stretch box-border flex flex-row items-center justify-between p-5 min-w-[400px] text-left text-xl text-neutral-black-300 font-inter border-b-[1px] border-solid border-menu-line-grey ${className}`}
    >
      <b className="relative tracking-[0.2px] leading-[26px]">
        {newAppointmentTitle}
      </b>
      <div className="flex flex-row items-end justify-start">
        <div className="overflow-hidden flex flex-row items-end justify-start p-[5px]">
          <img
            className="w-[14.4px] relative h-[14.4px]"
            alt=""
            src="/vector2.svg"
          />
        </div>
      </div>
    </div>
  );
};

export default PatientItemTopView;
