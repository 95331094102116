import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import TopNavBar from "../../components/nav/TopNavBar";
import SettingSideBar from "../../components/setting/sidebar/SettingSideBar";
import SettingProfileView from "../../components/setting/view/SettingProfileView";
import SettingBalanceView from "../../components/setting/view/SettingBalanceView";
import SettingNotificationView from "../../components/setting/view/SettingNotificationView";
import { AppDispatch, RootState } from "../../redux/store";
import { useDispatch } from "../../redux/hooks";
import { fetchUser } from "../../redux/features/user/userSlice";
import SettingResetPasswordView from "../../components/setting/view/SettingResetPasswordView";

const SettingPage: FunctionComponent = () => {
  const setting = useSelector((state: RootState) => state.nav.setting);
  const dispatch = useDispatch();
  let ViewComponent;
  switch (setting) {
    case "Balance":
      ViewComponent = SettingBalanceView;
      break;
    case "Notification":
      ViewComponent = SettingNotificationView;
      break;
    case "ResetPassword":
      ViewComponent = SettingResetPasswordView;
      break;
    case "Profile":
    default:
      ViewComponent = SettingProfileView;
      break;
  }
  return (
    <div className="w-full relative shadow-[0px_0px_124px_-15px_rgba(0,_0,_0,_0.25)] rounded-8xs bg-background-grey h-[1080px] overflow-hidden flex flex-col items-center justify-start">
      <TopNavBar />
      <div className="self-stretch flex-1 overflow-hidden flex flex-row items-center justify-start pt-2.5 pb-[60px] pr-20 pl-10 gap-[30px]">
        <SettingSideBar />
        <ViewComponent />
      </div>
    </div>
  );
};

export default SettingPage;
