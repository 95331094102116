import React, { useState, useEffect, ChangeEvent } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import InputFieldItem from "../../patient-info/item/InputFieldItem";
import { updateUser } from "../../../redux/features/user/userSlice";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  UpdateUserDto,
  UserRole,
} from "../../../redux/features/user/userTypes";
import { setSetting } from "../../../redux/features/nav/navSlice";
import AddressViewItem from "../../admin/item/AddressViewItem";

const SettingProfileView: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user.user);

  const [formState, setFormState] = useState<UpdateUserDto>({
    username: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    address: {
      city: "",
      zip: "",
      province: "",
      street: "",
    },
    userMetaData: { gender: "" },
    status: "",
    balance: 0,
  });
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (user) {
      setFormState({
        username: user.username,
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        phoneNumber: user.phoneNumber || "",
        address: user.address || {
          city: "",
          zip: "",
          province: "",
          street: "",
        },
        userMetaData: user.userMetaData || { gender: ""},
        status: user.status || "",
        balance: user.balance,
      });
    }
  }, [user]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    if (name === "gender") {
      setFormState((prevState) => ({
        ...prevState,
        userMetaData: { ...prevState.userMetaData, [name]: value },
      }));
    } else if (name in (formState.address || {})) {
      setFormState((prevState) => ({
        ...prevState,
        address: {
          ...prevState.address!,
          [name]: value,
        },
      }));
    } else {
      setFormState((prevState) => ({ ...prevState, [name]: value }));
    }
    setIsChanged(true);
  };

  const handleAddressChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      address: {
        ...prevState.address!,
        [name]: value,
      },
    }));
    setIsChanged(true);
  };

  const handleSubmit = () => {
    if (isChanged) {
      dispatch(updateUser(formState));
      setIsChanged(false);
    }
  };

  if (!user) {
    return null;
  }

  return (
    <div className="flex-1 bg-balance-background h-[949px] flex flex-col items-center justify-center py-0 px-5 box-border gap-[41px] text-left text-lg-7 text-gray-500 font-inter">
      <div className="self-stretch flex flex-row items-center justify-between text-17xl">
        <div className="flex-1 relative font-semibold">Profile</div>
        <img
          className="w-[65px] relative h-[65px]"
          alt=""
          src="/userprofileimg-1.svg"
        />
      </div>
      <div className="self-stretch flex flex-row items-center justify-between gap-[41px]">
        <div className="flex-1">
          <InputFieldItem
            label="First Name"
            name="firstName"
            value={formState.firstName || ""}
            onChange={handleChange}
          />
        </div>
        <div className="flex-1">
          <InputFieldItem
            label="Last Name"
            name="lastName"
            value={formState.lastName || ""}
            onChange={handleChange}
          />
        </div>
      </div>
      <InputFieldItem
        label="Email"
        name="email"
        value={user.email}
        disabled
        onChange={handleChange}
      />
      <InputFieldItem
        label="Phone Number"
        name="phoneNumber"
        value={formState.phoneNumber || ""}
        onChange={handleChange}
      />
      <AddressViewItem
        address={
          formState.address || { city: "", zip: "", province: "", street: "" }
        }
        onAddressChange={handleAddressChange}
      />
      <div className="self-stretch flex flex-row items-start justify-start gap-[41px]">
        <div className="flex-1 max-w-[200px]">
          <label className="block text-gray-700">Gender</label>
          <div className="mt-2">
            <label className="inline-flex items-center">
              <input
                type="radio"
                className="form-radio"
                name="gender"
                value="male"
                checked={formState.userMetaData?.gender === "male"}
                onChange={handleChange}
              />
              <span className="ml-2">Male</span>
            </label>
            <label className="inline-flex items-center ml-6">
              <input
                type="radio"
                className="form-radio"
                name="gender"
                value="female"
                checked={formState.userMetaData?.gender === "female"}
                onChange={handleChange}
              />
              <span className="ml-2">Female</span>
            </label>
          </div>
        </div>
      </div>
      <div className="self-stretch h-[122px] flex flex-row items-end justify-start gap-[41px]">
        <button
          className={`cursor-pointer [border:none] p-[15px] rounded-3xs flex flex-row items-center justify-start ${
            isChanged
              ? "bg-button-blue text-white"
              : "bg-gray-100 text-gray-400 cursor-not-allowed"
          }`}
          onClick={handleSubmit}
          disabled={!isChanged}
        >
          <div className="relative text-xl-2 font-semibold font-inter text-left">
            Update
          </div>
        </button>
        <button
          className="cursor-pointer p-[15px] bg-white rounded-3xs flex flex-row items-center justify-start border-[1px] border-solid border-button-blue"
          onClick={() => dispatch(setSetting("ResetPassword"))}
        >
          <div className="relative text-xl-2 font-semibold font-inter text-button-blue text-left">
            Change Password
          </div>
        </button>
        {user.role === UserRole.Patient && (
          <button
            className="cursor-pointer p-[15px] bg-white rounded-3xs flex flex-row items-center justify-start border-[1px] border-solid border-button-blue"
            onClick={() => navigate("/info-form")}
          >
            <div className="relative text-xl-2 font-semibold font-inter text-button-blue text-left">
              Update Form
            </div>
          </button>
        )}
      </div>
    </div>
  );
};

export default SettingProfileView;
