// src/components/BookAppointmentTimeSlotItem.tsx
import { FunctionComponent } from "react";

interface PatientBookAppointmentTimeSlotItemProps {
  time: string;
  appointmentId: number;
  onClick: () => void;
}

const PatientBookAppointmentTimeSlotItem: FunctionComponent<
  PatientBookAppointmentTimeSlotItemProps
> = ({ time, appointmentId, onClick }) => {
  return (
    <button
      className="cursor-pointer py-2.5 px-[41px] bg-[transparent] rounded-mini flex flex-row items-center justify-center border-[0.5px] border-solid border-strong hover:bg-darkslategray-300 hover:box-border hover:border-[0.5px] hover:border-solid hover:border-darkslategray-200"
      onClick={onClick}
    >
      <b className="relative text-xl tracking-[0.09px] leading-[18px] inline-block font-inter text-neutral-gray-dark-200 text-left min-w-[57px] whitespace-nowrap mq450:text-base mq450:leading-[14px]">
        {time}
      </b>
    </button>
  );
};

export default PatientBookAppointmentTimeSlotItem;
