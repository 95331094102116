import { User } from "../user/userTypes";

// src/redux/features/appointmentTypes.ts
export enum AppointmentState {
    Open = 'open',
    Pending = 'pending',
    Scheduled = 'scheduled',
    NotAvailable = 'notAvailable'
  }
  
  export interface Appointment {
    id: number;
    participants: User[];
    timeFrom: Date;
    timeTo: Date;
    description: string;
    state: AppointmentState;
    host: User;
  }
  
  export interface CreateAppointmentDto {
    participants: number[];
    timeFrom: Date;
    timeTo: Date;
    description?: string;
    state?: AppointmentState;
  }
  
  export interface UpdateAppointmentDto {
    participants?: number[];
    timeFrom?: Date;
    timeTo?: Date;
    description?: string;
    state?: AppointmentState;
  }
  
  export interface GetAppointmentsDto {
    states?: AppointmentState[];
    userType?: 'host' | 'participant' | 'all';
    startTime?: Date;
    endTime?: Date;
  }
  