import React, { FunctionComponent, ChangeEvent } from "react";

type InputFieldItemProps = {
  label: string;
  name: string;
  value: string | number | undefined;
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  disabled?: boolean;
  type?: string;
};

const InputFieldItem: FunctionComponent<InputFieldItemProps> = ({
  label,
  name,
  value,
  onChange,
  disabled = false,
  type = "text",
}) => {
  return (
    <div className="self-stretch flex flex-col items-start justify-start text-left text-lg-7 text-gray-500 font-inter">
      <div className="self-stretch relative font-semibold">{label}</div>
      {type === "select" ? (
        <select
          className="[outline:none] font-medium font-inter text-mid-3 bg-white self-stretch rounded-8xs flex flex-row items-center justify-start p-[15px] text-gray-100 border-[1px] border-solid border-text-field-surround-grey"
          name={name}
          value={value as string}
          onChange={onChange}
        >
          <option value="">Select Gender</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="other">Other</option>
        </select>
      ) : (
        <input
          className="[outline:none] font-medium font-inter text-mid-3 bg-white self-stretch rounded-8xs flex flex-row items-center justify-start p-[15px] text-gray-100 border-[1px] border-solid border-text-field-surround-grey"
          placeholder={label}
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default InputFieldItem;
