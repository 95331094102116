import { FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../../redux/store";
import { setSetting } from "../../../redux/features/nav/navSlice";
import { logout } from "../../../redux/features/auth/authSlice";

const SettingSideBar: FunctionComponent = () => {
  const dispatch: AppDispatch = useDispatch();
  const currentSetting = useSelector((state: RootState) => state.nav.setting);

  const handleSettingChange = (setting: string) => {
    dispatch(setSetting(setting as any));
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  const getButtonClass = (setting: string) =>
    currentSetting === setting ? "text-button-blue" : "";

  const user = useSelector((state: RootState) => state.user.user);

  const displayName =
    user?.firstName && user?.lastName
      ? `${user.firstName} ${user.lastName}`
      : user?.username || "Guest";

  return (
    <div className="self-stretch w-[387px] flex flex-col items-start justify-start py-[30px] px-5 box-border gap-[10px] text-left text-xl text-strong font-inter">
      <div className="self-stretch flex flex-row items-center justify-center p-5">
        <div className="flex-1 flex flex-row items-center justify-start gap-[21px]">
          <img
            className="w-[46px] relative h-[46px]"
            alt=""
            src="/userprofileimg.svg"
          />
          <b className="relative">{displayName}</b>
        </div>
      </div>
      <button
        className={`self-stretch flex flex-row items-center justify-between p-5 ${getButtonClass(
          "Profile"
        )}`}
        onClick={() => handleSettingChange("Profile")}
      >
        <div className="flex-1 flex flex-row items-center justify-start gap-[21px]">
          <img className="w-6 relative h-6" alt="" src="/user-alt.svg" />
          <b className="relative">Profile</b>
        </div>
        <img className="w-[7px] relative h-3.5" alt="" src="/righticon.svg" />
      </button>
      <button
        className={`self-stretch flex flex-row items-center justify-between p-5 ${getButtonClass(
          "Balance"
        )}`}
        onClick={() => handleSettingChange("Balance")}
      >
        <div className="flex-1 flex flex-row items-center justify-start gap-[20px]">
          <img className="w-6 relative h-6" alt="" src="/money-light.svg" />
          <b className="relative">Balance</b>
        </div>
        <img className="w-[7px] relative h-3.5" alt="" src="/righticon.svg" />
      </button>
      {/* <button
        className={`self-stretch flex flex-row items-center justify-between p-5 ${getButtonClass(
          "Notification"
        )}`}
        onClick={() => handleSettingChange("Notification")}
      >
        <div className="flex flex-row items-center justify-start gap-[20px]">
          <img className="w-6 relative h-6" alt="" src="/bell.svg" />
          <b className="relative">Notification</b>
        </div>
        <img className="w-[7px] relative h-3.5" alt="" src="/righticon.svg" />
      </button> */}
      <button
        className="self-stretch flex flex-row items-center justify-between p-5"
        onClick={handleLogout}
      >
        <div className="flex-1 flex flex-row items-center justify-start gap-[20px]">
          <img
            className="w-6 relative h-6"
            alt=""
            src="/sign-out-squre-light.svg"
          />
          <b className="relative">Log out</b>
        </div>
        <img className="w-[7px] relative h-3.5" alt="" src="/righticon.svg" />
      </button>
    </div>
  );
};

export default SettingSideBar;
