import React, { FunctionComponent, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AppDispatch, RootState } from "../../../redux/store";
import { fetchPatients } from "../../../redux/features/user/userSlice";
import { User } from "../../../redux/features/user/userTypes";
import {
  setCurrentPatient,
  setMenu,
} from "../../../redux/features/nav/navSlice";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { format } from "date-fns";

interface AdminPatientAccountsTableViewProps {
  searchQuery: string;
}

const AdminPatientAccountsTableView: FunctionComponent<
  AdminPatientAccountsTableViewProps
> = ({ searchQuery }) => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const patients = useSelector((state: RootState) => state.user.patients);

  useEffect(() => {
    dispatch(fetchPatients());
  }, [dispatch]);

  const handleViewClick = (patient: User) => {
    dispatch(setCurrentPatient(patient));
    dispatch(setMenu("Patients"));
    navigate("/patient-info");
  };

  const columnDefs: ColDef[] = [
    {
      headerName: "Patient Name",
      field: "patientName",
      valueFormatter: (params) => {
        const { firstName, lastName, username } = params.data;
        return firstName && lastName ? `${firstName} ${lastName}` : username;
      },
      flex: 1,
    },
    { headerName: "Email", field: "email", flex: 1 },
    { headerName: "Phone Number", field: "phoneNumber", flex: 1 },
    {
      headerName: "Date Added",
      field: "createDate",
      valueFormatter: (params) => new Date(params.value).toLocaleDateString(),
      flex: 1,
    },
    {
      headerName: "Gender",
      field: "userMetaData.gender",
      valueFormatter: (params) => params.value === 'female' ? 'Female' : params.value === 'male' ? 'Male' : "", 
    },
    {
      headerName: "Update Time",
      field: "updateDate",
      valueFormatter: (params) =>
        params.value ? format(new Date(params.value), "MMM dd, yyyy, hh:mm a") : "",
    },
    {
      headerName: "Action",
      field: "id",
      cellRenderer: (params: ICellRendererParams) => (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleViewClick(params.data)}
          >
            View
          </Button>
        </>
      ),
      flex: 1,
    },
  ];

  const filteredRowData =
    patients?.filter(
      (patient: User) =>
        (patient.firstName + " " + patient.lastName)
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        patient.email.toLowerCase().includes(searchQuery.toLowerCase())
    ) || [];

  return (
    <div className="ag-theme-alpine" style={{ height: 400, width: "100%" }}>
      <AgGridReact
        rowData={filteredRowData}
        columnDefs={columnDefs}
        domLayout="autoHeight"
      />
    </div>
  );
};

export default AdminPatientAccountsTableView;
