import React from "react";

interface PatientInfoFormMedicalItemProps {
  name: string;
  chineseName: string;
  enabled: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const PatientInfoFormMedicalItem: React.FC<PatientInfoFormMedicalItemProps> = ({
  name,
  chineseName,
  enabled,
  onChange,
}) => {
  return (
    <div className="box-border h-[81px] flex flex-row items-center justify-center py-5 pr-2.5 pl-5 min-w-[190px] max-w-[230px] text-center text-lg-9 text-strong font-roboto border-[0.5px] border-solid border-menu-text-gray">
      <input
        className="w-6 h-6 relative"
        type="checkbox"
        name={name}
        checked={enabled}
        onChange={onChange}
      />
      <div className="flex-1 relative">
        <p className="m-0 w-40">{name}</p>
        <p className="m-0">{chineseName}</p>
      </div>
    </div>
  );
};

export default PatientInfoFormMedicalItem;
