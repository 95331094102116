// src/components/PatientCancelAppointmentView.tsx
import { FunctionComponent, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAppointment } from "../../../redux/features/appointment/appointmentSlice";
import { AppDispatch, RootState } from "../../../redux/store";
import PatientItemTopView from "./PatientItemTopView";
import { AppointmentState } from "../../../redux/features/appointment/appointmentTypes";

interface PatientCancelAppointmentViewProps {
  appointmentId: number;
  onClose: () => void;
}

const PatientCancelAppointmentView: FunctionComponent<
  PatientCancelAppointmentViewProps
> = ({ appointmentId, onClose }) => {
  const dispatch: AppDispatch = useDispatch();

  const handleConfirmCancel = useCallback(() => {
    dispatch(
      updateAppointment({
        appointmentId,
        updateData: {
          participants: [],
          description: "",
          state: AppointmentState.Open,
        },
      })
    );
    onClose();
  }, [dispatch, appointmentId, onClose]);

  return (
    <div className="w-[800px] shadow-[0px_0px_4px_2px_rgba(0,_0,_0,_0.1)] rounded-xl bg-white max-w-full h-[485px] overflow-hidden flex flex-col items-center justify-between pt-0 px-0 pb-5 box-border text-justify text-xl text-neutral-black-300 font-inter">
      <PatientItemTopView newAppointmentTitle="Cancel Appointment" />
      <div className="self-stretch h-[266px] flex flex-col items-center justify-center py-0 px-[30px] box-border">
        <b className="relative tracking-[0.2px] leading-[26px]">
          Are you sure you want to cancel this appointment?
        </b>
      </div>
      <div className="self-stretch flex flex-row items-center justify-between py-0 px-[30px]">
        <button
          className="cursor-pointer py-[15px] px-[25px] bg-[transparent] rounded-3xs flex flex-row items-center justify-start border-[1px] border-solid border-button-blue"
          onClick={onClose}
        >
          <b className="relative text-sm tracking-[0.2px] leading-[18px] font-inter text-button-blue text-center">
            No, Keep Appointment
          </b>
        </button>
        <button
          className="cursor-pointer [border:none] py-[15px] px-[25px] bg-cancel-red rounded-3xs flex flex-row items-center justify-start"
          onClick={handleConfirmCancel}
        >
          <b className="relative text-sm tracking-[0.2px] leading-[18px] font-inter text-neutral-white text-center">
            Yes, Cancel Appointment
          </b>
        </button>
      </div>
    </div>
  );
};

export default PatientCancelAppointmentView;
