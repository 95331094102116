// src/components/patient-info/record/item/RecordFormAmountItem.tsx
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { UserRole } from "../../../../redux/features/user/userTypes";

export type RecordFormAmountItemProps = {
  label: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const RecordFormAmountItem: React.FC<RecordFormAmountItemProps> = ({
  label,
  name,
  value,
  onChange,
}) => {
  const currentUser = useSelector((state: RootState) => state.user.user);
  const disabled = currentUser?.role === UserRole.Patient;
  return (
    <div className="self-stretch flex flex-row items-start justify-start py-0 px-[30px] box-border gap-[30px] min-w-[750px]">
      <div className="w-[120px] relative inline-block shrink-0">{label}</div>
      <input
        className="[outline:none] bg-neutral-white relative rounded-md box-border h-[33px] w-[100px] border-[1px] border-solid border-text-field-surround-grey"
        type="text"
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
};
