// src/redux/store.ts
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './features/auth/authSlice';
import userReducer from './features/user/userSlice';
import navReducer from './features/nav/navSlice';
import recordReducer from './features/record/recordSlice';
import appointmentReducer from './features/appointment/appointmentSlice';
import { loadStateFromLocalStorage } from './localStorage';

const persistedState = {
  appointment: loadStateFromLocalStorage('appointmentState'),
  auth: loadStateFromLocalStorage('authState'),
  user: loadStateFromLocalStorage('userState'),
  record: loadStateFromLocalStorage('recordState'),
  nav: loadStateFromLocalStorage('navState'),
};

const store = configureStore({
  reducer: {
    auth: authReducer,
    nav: navReducer,
    user: userReducer,
    appointment: appointmentReducer,
    record: recordReducer,
  },
  preloadedState: persistedState,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
