import { FunctionComponent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TopNavBar from "../../components/nav/TopNavBar";
import AdminDashboardViewItem from "../../components/admin/item/AdminDashboardViewItem";
import AdminStatisticClientView from "../../components/admin/view/statistic/AdminStatisticClientView";
import AdminStatisticInsuranceView from "../../components/admin/view/statistic/AdminStatisticInsuranceView";
import AdminStatisticTreatmentView from "../../components/admin/view/statistic/AdminStatisticTreatmentView";
import AdminStatisticCashFlowView from "../../components/admin/view/statistic/AdminStatisticCashFlowView";
import { RootState, AppDispatch } from "../../redux/store";
import { NavState } from "../../redux/features/nav/navTypes";
import { GroupByPeriod } from "../../redux/features/record/recordTypes";
import {
  isSameDay,
  isSameMonth,
  isSameWeek,
  isSameYear,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from "date-fns";

const AdminDashboardPage: FunctionComponent = () => {
  const navState = useSelector((state: RootState) => state.nav);
  const clients = useSelector((state: RootState) => state.record.clients);
  const cashflows = useSelector((state: RootState) => state.record.cashflows);
  const treatments = useSelector((state: RootState) => state.record.treatments);
  const insurances = useSelector((state: RootState) => state.record.insurances);
  const [currentDashboard, setCurrentDashboard] = useState("cashflow");

  const handleDashboardChange = (dashboard: NavState["dashboard"]) => {
    setCurrentDashboard(dashboard);
    // Update the nav state in Redux if needed
  };

  const calculatePercentageChange = (current: number, previous: number) => {
    if (previous === 0) return "0%";
    return `${(((current - previous) / previous) * 100).toFixed(2)}%`;
  };

  const verifyPeriod = (date: Date, period: Date, groupBy: GroupByPeriod) => {
    switch (groupBy) {
      case GroupByPeriod.Day:
        return isSameDay(date, period);
      case GroupByPeriod.Week:
        return isSameWeek(date, period, { weekStartsOn: 1 });
      case GroupByPeriod.Month:
        return isSameMonth(date, period);
      case GroupByPeriod.Year:
        return isSameYear(date, period);
      default:
        return false;
    }
  };

  const getFirstPeriodTotal = (data: any, groupBy: GroupByPeriod) => {
    if (!data || !data.periods || data.periods.length === 0) return 0;
    const now = new Date();
    const periodDate = new Date(data.periods[0].periodDate);
    return verifyPeriod(now, periodDate, groupBy) ? data.periods[0].total : 0;
  };

  const getPreviousPeriodTotal = (data: any, groupBy: GroupByPeriod) => {
    if (!data || !data.periods || data.periods.length < 2) return 0;
    const now = new Date();
    let previousPeriod: Date;
    switch (groupBy) {
      case GroupByPeriod.Day:
        previousPeriod = subDays(now, 1);
        break;
      case GroupByPeriod.Week:
        previousPeriod = subWeeks(now, 1);
        break;
      case GroupByPeriod.Month:
        previousPeriod = subMonths(now, 1);
        break;
      case GroupByPeriod.Year:
        previousPeriod = subYears(now, 1);
        break;
      default:
        previousPeriod = now;
    }
    const periodDate = new Date(data.periods[1].periodDate);
    return verifyPeriod(previousPeriod, periodDate, groupBy)
      ? data.periods[1].total
      : 0;
  };

  const renderStatisticView = () => {
    switch (currentDashboard) {
      case "client":
        return <AdminStatisticClientView />;
      case "insurance":
        return <AdminStatisticInsuranceView />;
      case "treatment":
        return <AdminStatisticTreatmentView />;
      case "cashflow":
      default:
        return <AdminStatisticCashFlowView />;
    }
  };

  return (
    <div className="w-full relative shadow-[0px_0px_124px_-15px_rgba(0,_0,_0,_0.25)] rounded-8xs bg-background-grey h-[1080px] overflow-hidden flex flex-col items-center justify-start text-left text-9xl text-gray-300 font-inter">
      <TopNavBar />
      <div className="self-stretch flex-1 overflow-hidden flex flex-col items-start justify-start p-[30px] gap-[10px]">
        <div className="w-full flex flex-row items-center justify-between py-0 pr-[50px] pl-0 box-border min-w-[1100px] max-w-[1450px]">
          <b className="relative tracking-[-0.01em] leading-[120%]">Overview</b>
        </div>
        <div className="self-stretch flex flex-row items-center justify-between gap-[10px] text-base">
          <AdminDashboardViewItem
            icon="/wallet-alt-fill@2x.png"
            title="Income"
            amount={`$${getFirstPeriodTotal(cashflows, cashflows?.groupBy!)}`}
            percentage={calculatePercentageChange(
              getFirstPeriodTotal(cashflows, cashflows?.groupBy!),
              getPreviousPeriodTotal(cashflows, cashflows?.groupBy!)
            )}
            groupBy={cashflows?.groupBy!}
            onClick={() => handleDashboardChange("cashflow")}
          />
          <AdminDashboardViewItem
            icon="/treamenticon1.svg"
            title="Treatment"
            amount={`${getFirstPeriodTotal(treatments, treatments?.groupBy!)}`}
            percentage={calculatePercentageChange(
              getFirstPeriodTotal(treatments, treatments?.groupBy!),
              getPreviousPeriodTotal(treatments, treatments?.groupBy!)
            )}
            groupBy={treatments?.groupBy!}
            onClick={() => handleDashboardChange("treatment")}
          />
          <AdminDashboardViewItem
            icon="/clienticon.svg"
            title="Client"
            amount={`${getFirstPeriodTotal(clients, clients?.groupBy!)}`}
            percentage={calculatePercentageChange(
              getFirstPeriodTotal(clients, clients?.groupBy!),
              getPreviousPeriodTotal(clients, clients?.groupBy!)
            )}
            groupBy={clients?.groupBy!}
            onClick={() => handleDashboardChange("client")}
          />
          <AdminDashboardViewItem
            icon="/date-fill.svg"
            title="Insurance"
            amount={`${getFirstPeriodTotal(insurances, insurances?.groupBy!)}`}
            percentage={calculatePercentageChange(
              getFirstPeriodTotal(insurances, insurances?.groupBy!),
              getPreviousPeriodTotal(insurances, insurances?.groupBy!)
            )}
            groupBy={insurances?.groupBy!}
            onClick={() => handleDashboardChange("insurance")}
          />
        </div>
        <div className="self-stretch flex flex-row items-center justify-start py-5 px-0">
          <b className="relative tracking-[-0.01em] leading-[120%]">
            Recently Viewed
          </b>
        </div>
        {renderStatisticView()}
      </div>
    </div>
  );
};

export default AdminDashboardPage;
