import React, { FunctionComponent, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../redux/store";
import BillingItem from "../item/BillingItem";
import { fetchUserRecords } from "../../../redux/features/record/recordSlice";
import { Record } from "../../../redux/features/record/recordTypes";
import { UserRole } from "../../../redux/features/user/userTypes";

const SettingBalanceView: FunctionComponent = () => {
  const dispatch: AppDispatch = useDispatch();
  const currentUser = useSelector((state: RootState) => state.user.user);
  const [billingItems, setBillingItems] = useState<Record[]>([]);

  useEffect(() => {
    if (currentUser) {
      dispatch(
        fetchUserRecords({
          userId: currentUser.id,
          type: currentUser.role == UserRole.Doctor ? "creator" : "owner",
        })
      );
    }
  }, [dispatch, currentUser]);

  const { userRecords } = useSelector((state: RootState) => state.record);

  useEffect(() => {
    if (userRecords && currentUser) {
      const filteredRecords = userRecords
        .filter((record) => record.fee > 0)
        .filter((record) =>
          currentUser.role === UserRole.Doctor
            ? record.creator.id === currentUser.id
            : record.owner.id === currentUser.id
        )
        .slice(0, 10);
      setBillingItems(filteredRecords);
    }
  }, [userRecords, currentUser]);

  const formatFee = (record: Record) => {
    if (
      currentUser.role === UserRole.Patient &&
      record.type !== "LoadBalance"
    ) {
      return `-$${record.fee - (record.isCovered ? record.coverage : 0)}`;
    }
    return `$${record.fee}`;
  };

  const formatBalance = (balance: number) => {
    const formattedBalance = balance;
    return balance < 0
      ? `-$${Math.abs(formattedBalance)}`
      : `$${formattedBalance}`;
  };

  return (
    <div className="self-stretch w-[800px] rounded-xl bg-balance-background flex flex-col items-start justify-start p-5 box-border gap-[10px] text-left text-base text-gray-700 font-inter">
      <div className="self-stretch relative text-17xl font-black">Balance</div>
      <div className="self-stretch flex flex-row items-center justify-between py-5 px-0 border-b-[1.5px] border-solid border-strong">
        <div className="relative uppercase">Current Balance</div>
        <div className="relative text-xl">
          {currentUser?.balance !== undefined
            ? formatBalance(currentUser.balance)
            : "0.00"}
        </div>
      </div>
      <div className="self-stretch flex flex-row items-center justify-start py-5 px-0 border-b-[1.5px] border-solid border-strong">
        <div className="relative">BILLING HISTORY</div>
      </div>
      <div className="self-stretch flex flex-col items-start justify-start py-5 px-0 gap-[10px]">
        {billingItems.map((record) => (
          <BillingItem
            key={record.id}
            date={new Date(record.createDate).toLocaleDateString()}
            reason={record.type}
            amount={formatFee(record)}
          />
        ))}
      </div>
    </div>
  );
};

export default SettingBalanceView;
