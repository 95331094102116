// src/components/patient-info/record/RecordPopup.tsx
import React, { FunctionComponent } from "react";
import PortalPopup from "./PortalPopup";
import RecordAcupunctureView from "./RecordAcupunctureView";
import RecordHerbView from "./RecordHerbView";
import RecordConsultingView from "./RecordConsultingView";
import RecordLoadBalanceView from "./RecordLoadBalanceVIew";

import NewOtherView from "./RecordOtherView";

interface RecordPopupProps {
  type: string | null;
  onClose: () => void;
}

const RecordPopup: FunctionComponent<RecordPopupProps> = ({
  type,
  onClose,
}) => {
  const renderPopupContent = () => {
    switch (type) {
      case "Acupuncture":
        return <RecordAcupunctureView onClose={onClose} />;
      case "Herb":
        return <RecordHerbView onClose={onClose} />;
      case "Consulting":
        return <RecordConsultingView onClose={onClose} />;
      case "Other":
        return <NewOtherView onClose={onClose} />;
      case "LoadBalance":
        return <RecordLoadBalanceView onClose={onClose} />;
      default:
        return null;
    }
  };

  if (!type) return null;

  return (
    <PortalPopup
      overlayColor="rgba(113, 113, 113, 0.3)"
      placement="Centered"
      onOutsideClick={onClose}
    >
      {renderPopupContent()}
    </PortalPopup>
  );
};

export default RecordPopup;
