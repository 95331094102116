import { FunctionComponent, useState } from "react";

type FilterPopupProps = {
  onApply: (startDate: string, endDate: string, periods: number) => void;
  onClose: () => void;
  groupBy: string;
};

const FilterPopup: FunctionComponent<FilterPopupProps> = ({
  onApply,
  onClose,
  groupBy,
}) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [periods, setPeriods] = useState(0);

  const maxPeriods = () => {
    switch (groupBy) {
      case "day":
        return 14;
      case "week":
        return 10;
      case "month":
        return 12;
      case "year":
        return 5;
      default:
        return 0;
    }
  };

  const handleApply = () => {
    onApply(startDate, endDate, periods);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg p-6 shadow-lg w-1/2">
        <h2 className="text-xl mb-4">Filter Records</h2>
        <div className="mb-4">
          <label className="block text-gray-700">Start Date</label>
          <input
            type="date"
            className="border rounded w-full py-2 px-3"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">End Date</label>
          <input
            type="date"
            className="border rounded w-full py-2 px-3"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Periods</label>
          <input
            type="number"
            className="border rounded w-full py-2 px-3"
            value={periods}
            onChange={(e) => setPeriods(Number(e.target.value))}
            min={1}
            max={maxPeriods()}
          />
          <p className="text-gray-600">Max: {maxPeriods()} periods</p>
        </div>
        <div className="flex justify-between">
          <button
            className="px-4 py-2 border rounded bg-transparent"
            style={{ borderColor: '#FF6760', color: '#FF6760' }}
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 rounded border"
            style={{ backgroundColor: '#2F80ED', borderColor: '#2F80ED', color: '#fff' }}
            onClick={handleApply}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterPopup;
