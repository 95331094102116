import { Appointment } from "../appointment/appointmentTypes";
import { User } from "../user/userTypes";

export enum RecordType {
    Acupuncture = 'Acupuncture',
    Herb = 'Herb',
    Consulting = 'Consulting',
    LoadBalance = 'LoadBalance',
    Statement = 'Statement', 
    Other = 'Other'
  }
  
  export interface Record {
    id: number;
    creator: User;
    owner: User;
    type: RecordType;
    form: any;
    fee: number;
    insurance: string | null;
    coverage: number;
    isCovered?: boolean;
    createDate: Date;
    updatedAt: Date;
    appointment?: Appointment;
  }
  
  export interface CreateRecordDto {
    ownerId: number;
    form: object;
    fee?: number;
    type: RecordType;
    insurance?: string | null;
    coverage?: number;
    isCovered?: boolean;
    appointmentId?: number;
  }
  
  export interface UpdateRecordDto {
    form?: object;
    fee?: number;
    type?: RecordType;
    insurance?: string | null;
    coverage?: number;
    isCovered?: boolean;
    appontmentId?: number;
  }
  
  export enum RecordSearchType {
    Cashflow = 'cashflow',
    Client = 'client',
    Insurance = 'insurance',
    Treatment = 'treatment',
    All = 'all'
  }
  
  export enum GroupByPeriod {
    Day = 'day',
    Week = 'week',
    Month = 'month',
    Year = 'year'
  }
  
  export interface StatisticRecord {
    periods: any[];
    subtotal: any;
    startPeriod?: Date;
    endPeriod?: Date;
    periodsNum: number;
    groupBy: GroupByPeriod;
  }

  export interface SearchRecordDto {
    type: RecordSearchType;
    groupBy: GroupByPeriod;
    startPeriod?: Date;
    endPeriod?: Date;
    periods?: number;
  }
  