// src/redux/features/appointment/appointmentSlice.ts

import { createAsyncThunk, createSlice, isPending, isRejectedWithValue } from '@reduxjs/toolkit';
import { apiRequest } from '../../api';
import { Appointment, CreateAppointmentDto, UpdateAppointmentDto, GetAppointmentsDto, AppointmentState as ApptState } from './appointmentTypes';
import { saveStateToLocalStorage, loadStateFromLocalStorage, removeStateFromLocalStorage } from '../../localStorage';
import { isToday, isThisWeek, isFuture } from 'date-fns';
import logger from '../../../util/logger';

interface AppointmentState {
  appointments: Appointment[]; 
  doctorAppointments: Appointment[]; 
  todayAppointments: Appointment[]; 
  appointmentThisWeek: Appointment[]; 
  upcomingAppointments: Appointment[]; 
  appointmentsHistory: Appointment[]; 
  isLoading: boolean;
  error: string | null;
}

const initialState: AppointmentState = {
  appointments: [], 
  doctorAppointments: [],
  todayAppointments: [], 
  appointmentThisWeek: [], 
  upcomingAppointments: [], 
  appointmentsHistory: [], 
  isLoading: false,
  error: null,
};

const persistedAppointmentState = loadStateFromLocalStorage('appointmentState') || initialState;

const filterAppointments = (appointments: Appointment[]) => {
  const todayAppointments: Appointment[] = [];
  const appointmentThisWeek: Appointment[] = [];
  const upcomingAppointments: Appointment[] = [];
  const appointmentsHistory: Appointment[] = [];

  appointments.forEach(appointment => {
    if (isToday(new Date(appointment.timeFrom))) {
      todayAppointments.push(appointment);
    }
    if (isThisWeek(new Date(appointment.timeFrom))) {
      appointmentThisWeek.push(appointment);
    }
    if (isFuture(new Date(appointment.timeFrom)) && (appointment.state === ApptState.Pending || appointment.state === ApptState.Scheduled)) {
      upcomingAppointments.push(appointment);
    }
    if (new Date(appointment.timeTo) < new Date() && appointment.state === ApptState.Scheduled) {
      appointmentsHistory.push(appointment);
    }
  });

  return { todayAppointments, appointmentThisWeek, upcomingAppointments, appointmentsHistory };
};

export const fetchAppointments = createAsyncThunk('appointments/fetchAppointments', async (getAppointmentsDto: GetAppointmentsDto) => {
  const response = await apiRequest({ url: 'appointments/all', method: 'POST', body: getAppointmentsDto });
  return response as Appointment[];
});

export const fetchAppointmentsAdmin = createAsyncThunk('appointments/fetchAppointmentsAdmin', async (getAppointmentsDto: GetAppointmentsDto) => {
  const response = await apiRequest({ url: 'appointments/all/admin', method: 'POST', body: getAppointmentsDto });
  return response as Appointment[];
});

export const fetchDoctorAppointmentsById = createAsyncThunk('appointments/fetchDoctorAppointmentsById', async (userId: number) => {
  const response = await apiRequest({
    url: `appointments/user/${userId}`,
    method: 'POST',
    body: { states: ['open'], userType: 'host' },
  });
  return response as Appointment[];
});

export const fetchAppointmentById = createAsyncThunk('appointments/fetchAppointmentById', async (appointmentId: number) => {
  const response = await apiRequest({ url: `appointments/${appointmentId}`, method: 'GET' });
  return response as Appointment;
});

export const createAppointment = createAsyncThunk('appointments/createAppointment', async (appointmentData: CreateAppointmentDto) => {
  const response = await apiRequest({ url: 'appointments', method: 'POST', body: appointmentData });
  return response as Appointment;
});

export const updateAppointment = createAsyncThunk('appointments/updateAppointment', async ({ appointmentId, updateData }: { appointmentId: number; updateData: UpdateAppointmentDto }) => {
  const response = await apiRequest({ url: `appointments/${appointmentId}`, method: 'PATCH', body: updateData });
  return response as Appointment;
});

export const deleteAppointment = createAsyncThunk('appointments/deleteAppointment', async (appointmentId: number) => {
  await apiRequest({ url: `appointments/${appointmentId}`, method: 'DELETE' });
  return appointmentId;
});

const appointmentSlice = createSlice({
  name: 'appointments',
  initialState: persistedAppointmentState,
  reducers: {
    clearAppointmentState: (state) => {
      Object.assign(state, initialState);
      removeStateFromLocalStorage('appointmentState');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAppointments.fulfilled, (state, action) => {
        state.appointments = action.payload;
        const filtered = filterAppointments(state.appointments);
        state.todayAppointments = filtered.todayAppointments;
        state.appointmentThisWeek = filtered.appointmentThisWeek;
        state.upcomingAppointments = filtered.upcomingAppointments;
        state.appointmentsHistory = filtered.appointmentsHistory;
        logger.log(filtered)
        saveStateToLocalStorage('appointmentState', state);
      })
      .addCase(fetchAppointmentsAdmin.fulfilled, (state, action) => {
        state.appointments = action.payload;
        saveStateToLocalStorage('appointmentState', state);
      })
      .addCase(fetchDoctorAppointmentsById.fulfilled, (state, action) => {
        state.doctorAppointments = action.payload;
        saveStateToLocalStorage('appointmentState', state);
      })
      .addCase(fetchAppointmentById.fulfilled, (state, action) => {
        const index = state.appointments.findIndex((appt: Appointment) => appt.id === action.payload.id);
        if (index !== -1) {
          state.appointments[index] = action.payload;
        } else {
          state.appointments.push(action.payload);
        }
        saveStateToLocalStorage('appointmentState', state);
      })
      .addCase(createAppointment.fulfilled, (state, action) => {
        state.appointments.push(action.payload);
        saveStateToLocalStorage('appointmentState', state);
      })
      .addCase(updateAppointment.fulfilled, (state, action) => {
        const index = state.appointments.findIndex((appt: Appointment) => appt.id === action.payload.id);
        if (index !== -1) {
          state.appointments[index] = action.payload;
        }
        saveStateToLocalStorage('appointmentState', state);
      })
      .addCase(deleteAppointment.fulfilled, (state, action) => {
        state.appointments = state.appointments.filter((appt: Appointment) => appt.id !== action.payload);
        saveStateToLocalStorage('appointmentState', state);
      })
      .addMatcher(isPending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addMatcher(isRejectedWithValue, (state, action) => {
        state.isLoading = false;
        state.error = 'An error occurred';
      })
      .addMatcher(
        (action) => action.type.endsWith('/fulfilled'),
        (state) => {
          state.isLoading = false;
          state.error = null;
        }
      );
  },
});

export const { clearAppointmentState } = appointmentSlice.actions;

export default appointmentSlice.reducer;
