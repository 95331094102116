import { FunctionComponent, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import StatisticHeader from "./StatisticHeader";
import FilterPopup from "./FilterPopup";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgChartsReact } from "ag-charts-react";
import { fetchRecordStatistics } from "../../../../redux/features/record/recordSlice";
import { RootState, AppDispatch } from "../../../../redux/store";
import {
  RecordSearchType,
  GroupByPeriod,
} from "../../../../redux/features/record/recordTypes";

const AdminStatisticTreatmentView: FunctionComponent = () => {
  const dispatch: AppDispatch = useDispatch();
  const treatments = useSelector((state: RootState) => state.record.treatments);
  const [showFilter, setShowFilter] = useState(false);
  const [groupBy, setGroupBy] = useState<GroupByPeriod>(
    treatments ? treatments.groupBy : GroupByPeriod.Day
  );
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [periods, setPeriods] = useState<number | undefined>(undefined);

  const columnDefs = [
    {
      headerName: "Period",
      field: "period",
      valueGetter: (params: any) => ({
        display: params.data?.period,
        sortValue: new Date(params.data?.periodDate),
      }),
      comparator: (a: any, b: any) => a.sortValue - b.sortValue,
      cellRenderer: (params: any) => params.value.display,
    },
    { headerName: "Period Date", field: "periodDate", hide: true },
    { headerName: "Acupuncture", field: "acupuncture", type: "numericColumn" },
    { headerName: "Herb", field: "herb", type: "numericColumn" },
    { headerName: "Consulting", field: "consulting", type: "numericColumn" },
    { headerName: "Other", field: "other", type: "numericColumn" },
    { headerName: "Total", field: "total", type: "numericColumn" },
  ];

  useEffect(() => {
    fetchData();
  }, [groupBy, startDate, endDate]);

  const fetchData = () => {
    const params = {
      type: RecordSearchType.Treatment,
      groupBy,
      startPeriod: startDate ? new Date(startDate) : undefined,
      endPeriod: endDate ? new Date(endDate) : undefined,
      periods: endDate ? periods : undefined,
    };
    dispatch(fetchRecordStatistics(params));
  };

  const handleFilterApply = (start: string, end: string, periods: number) => {
    setStartDate(start);
    setEndDate(end);
    setPeriods(periods);
    fetchData();
  };

  return (
    <div className="w-full relative rounded-8xs bg-background-grey h-[1080px] overflow-hidden flex flex-col items-start justify-start text-left text-3xl text-gray-400 font-inter p-10">
      <div className="self-stretch flex-1 overflow-hidden flex flex-col items-start justify-start gap-[30px]">
        <div className="flex flex-row items-center justify-start w-full">
          <div className="relative leading-[21px] font-medium">
            Treatment Statistic
          </div>
        </div>
        <div className="self-stretch flex-1 rounded-3xs bg-white overflow-hidden flex flex-col p-5 box-border gap-[30px] text-xs text-color">
          <div className="flex flex-row items-center justify-between">
            <StatisticHeader
              groupBy={groupBy}
              setGroupBy={setGroupBy}
              onFilterClick={() => setShowFilter(true)}
            />
          </div>
          <div className="flex-1 flex flex-row items-start justify-between gap-[30px]">
            <div className="ag-theme-alpine flex-1 min-w-0" style={{ height: 400 }}>
              <AgGridReact
                rowData={treatments?.periods || []}
                columnDefs={columnDefs}
                gridOptions={{ pinnedBottomRowData: [treatments?.subtotal] }}
              />
            </div>
            <div className="ag-theme-alpine flex-1 min-w-0" style={{ height: 400 }}>
              <AgChartsReact
                options={{
                  data: treatments?.periods || [],
                  series: [
                    {
                      type: "bar",
                      xKey: "period",
                      yKey: "total",
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {showFilter && (
        <FilterPopup
          onApply={handleFilterApply}
          onClose={() => setShowFilter(false)}
          groupBy={groupBy}
        />
      )}
    </div>
  );
};

export default AdminStatisticTreatmentView;
