import React, { FunctionComponent, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AppDispatch, RootState } from "../../../redux/store";
import { fetchPatients } from "../../../redux/features/user/userSlice";
import { User } from "../../../redux/features/user/userTypes";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  setCurrentPatient,
  setMenu,
} from "../../../redux/features/nav/navSlice";
import { fetchAppointments } from "../../../redux/features/appointment/appointmentSlice";
import { Appointment } from "../../../redux/features/appointment/appointmentTypes";
import { format } from "date-fns";
import logger from "../../../util/logger";

interface DoctorPatientAccountsTableViewProps {
  searchQuery: string;
}

const DoctorPatientAccountsTableView: FunctionComponent<
  DoctorPatientAccountsTableViewProps
> = ({ searchQuery }) => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const patients = useSelector((state: RootState) => state.user.patients);
  const appointments = useSelector(
    (state: RootState) => state.appointment.appointments
  );

  const [lastVisits, setLastVisits] = useState<{ [key: number]: string }>({});

  useEffect(() => {
    dispatch(fetchPatients());
    dispatch(fetchAppointments({ userType: "all" })); // Provide necessary argument
  }, [dispatch]);

  useEffect(() => {
    const visits: { [key: number]: string } = {};
    appointments.forEach((appointment: Appointment) => {
      appointment.participants.forEach((participant) => {
        if (
          !visits[participant.id] ||
          new Date(appointment.timeFrom) > new Date(visits[participant.id])
        ) {
          visits[participant.id] = new Date(appointment.timeFrom).toISOString();
        }
      });
    });
    setLastVisits(visits);
    logger.log("Last Visits: ", visits); // Debugging line to log the last visits
  }, [appointments]);

  const handleViewClick = (patient: User) => {
    dispatch(setCurrentPatient(patient));
    dispatch(setMenu("Patients"));
    navigate("/patient-info");
  };

  const filteredPatients = patients.filter((patient: User) => {
    const { firstName, lastName, username, email, phoneNumber } = patient;
    const searchStr = searchQuery.toLowerCase();
    return (
      firstName?.toLowerCase().includes(searchStr) ||
      lastName?.toLowerCase().includes(searchStr) ||
      username.toLowerCase().includes(searchStr) ||
      email.toLowerCase().includes(searchStr) ||
      phoneNumber?.toLowerCase().includes(searchStr)
    );
  });

  const columnDefs: ColDef[] = [
    {
      headerName: "Patient Name",
      field: "patientName",
      valueFormatter: (params) => {
        const { firstName, lastName, username } = params.data;
        return firstName && lastName ? `${firstName} ${lastName}` : username;
      },
    },
    { headerName: "Email", field: "email" },
    { headerName: "Phone Number", field: "phoneNumber" },
    {
      headerName: "Date Added",
      field: "createDate",
      valueFormatter: (params) => new Date(params.value).toLocaleDateString(),
    },
    {
      headerName: "Gender",
      field: "userMetaData.gender",
      valueFormatter: (params) =>
        params.value === "female"
          ? "Female"
          : params.value === "male"
          ? "Male"
          : "",
    },
    {
      headerName: "Last Visit",
      field: "lastVisit",
      valueFormatter: (params) =>
        lastVisits[params.data.id]
          ? format(
              new Date(lastVisits[params.data.id]),
              "MMM dd, yyyy, hh:mm a"
            )
          : "",
    },
    {
      headerName: "Action",
      field: "id",
      cellRenderer: (params: ICellRendererParams) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleViewClick(params.data)}
        >
          View
        </Button>
      ),
    },
  ];

  const rowData =
    filteredPatients?.map((patient: User) => ({
      ...patient,
      lastVisit: lastVisits[patient.id],
    })) || [];
  logger.log("Row Data: ", rowData); // Debugging line to log the row data

  return (
    <div className="ag-theme-alpine" style={{ height: 400, width: "100%" }}>
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        domLayout="autoHeight"
      />
    </div>
  );
};

export default DoctorPatientAccountsTableView;
