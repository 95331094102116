import React, { FunctionComponent, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AppDispatch, RootState } from "../../../redux/store";
import { User } from "../../../redux/features/user/userTypes";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { setCurrentDoctor } from "../../../redux/features/nav/navSlice";

interface AdminDoctorAccountsTableViewProps {
  searchQuery: string;
}

const AdminDoctorAccountsTableView: FunctionComponent<
  AdminDoctorAccountsTableViewProps
> = ({ searchQuery }) => {
  const doctors = useSelector((state: RootState) => state.user.doctors);
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const handleViewClick = (doctor: User) => {
    dispatch(setCurrentDoctor(doctor));
    navigate("/add-doctor");
  };

  const columnDefs: ColDef[] = [
    {
      headerName: "Doctor Name",
      field: "doctorName",
      flex: 1,
      valueFormatter: (params) => {
        const { firstName, lastName, username } = params.data;
        return firstName && lastName ? `${firstName} ${lastName}` : username;
      },
    },
    { headerName: "Email", field: "email", flex: 1 },
    { headerName: "Phone Number", field: "phoneNumber", flex: 1 },
    {
      headerName: "Date Added",
      field: "createDate",
      flex: 1,
      valueFormatter: (params) => new Date(params.value).toLocaleDateString(),
    },
    {
      headerName: "Gender",
      field: "userMetaData.gender",
      flex: 1,
      valueFormatter: (params) =>
        params.value === "female" ? "Female" : params.value === "male" ? "Male" : "",
    },
    {
      headerName: "Type",
      field: "type",
      flex: 1,
      valueFormatter: (params) => params.value || "",
    },
    {
      headerName: "Status",
      field: "status",
      flex: 1,
      valueFormatter: (params) => params.value || "",
    },
    {
      headerName: "Date Employed",
      field: "userMetaData.dateEmployed",
      flex: 1,
      valueFormatter: (params) =>
        params.value ? new Date(params.value).toLocaleDateString() : "",
    },
    {
      headerName: "Action",
      field: "id",
      flex: 1,
      cellRenderer: (params: ICellRendererParams) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleViewClick(params.data)}
        >
          View
        </Button>
      ),
    },
  ];

  const filteredRowData =
    doctors?.filter(
      (doctor: User) =>
        (doctor.firstName + " " + doctor.lastName)
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        doctor.email.toLowerCase().includes(searchQuery.toLowerCase())
    ) || [];

  return (
    <div className="ag-theme-alpine" style={{ height: 400, width: "100%" }}>
      <AgGridReact
        rowData={filteredRowData}
        columnDefs={columnDefs}
        domLayout="autoHeight"
        defaultColDef={{ resizable: true }}
      />
    </div>
  );
};

export default AdminDoctorAccountsTableView;
