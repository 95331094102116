import { FunctionComponent } from "react";

const SettingNotificationView: FunctionComponent = () => {
  return (
    <div className="self-stretch w-[800px] rounded-xl bg-balance-background flex flex-col items-start justify-start p-5 box-border gap-[10px] text-left text-xl text-gray-700 font-inter">
      <div className="self-stretch relative text-17xl font-black">
        Notification
      </div>
      <div className="self-stretch flex flex-row items-center justify-start py-5 px-2.5 border-b-[1.5px] border-solid border-strong">
        <div className="relative font-black">Appointment Notifications</div>
      </div>
      <div className="self-stretch flex flex-col items-start justify-center py-5 px-2.5 gap-[10px] text-lg">
        <div className="flex flex-row items-start justify-start gap-[9px]">
          <input className="m-0 w-[23px] relative h-[23px]" type="checkbox" />
          <div className="relative font-medium">
            <p className="m-0">{`Receive notification of new, canceled, `}</p>
            <p className="m-0">and rescheduled appointed</p>
          </div>
        </div>
        <div className="flex flex-row items-start justify-start gap-[9px]">
          <input className="m-0 w-[23px] relative h-[23px]" type="checkbox" />
          <div className="relative font-medium">
            Provide feedback on experience
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-row items-center justify-start py-5 px-2.5 border-b-[1.5px] border-solid border-strong">
        <div className="relative font-black">Appointment Notifications</div>
      </div>
      <div className="self-stretch flex flex-col items-start justify-center py-5 px-2.5 gap-[10px] text-lg">
        <div className="flex flex-row items-start justify-start gap-[9px]">
          <input className="m-0 w-[23px] relative h-[23px]" type="checkbox" />
          <div className="relative font-medium">
            Phone Call 2 days before appointment
          </div>
        </div>
        <div className="flex flex-row items-start justify-start gap-[9px]">
          <input className="m-0 w-[23px] relative h-[23px]" type="checkbox" />
          <div className="relative font-medium">
            Email 2 days before appointment
          </div>
        </div>
        <div className="flex flex-row items-start justify-start gap-[9px]">
          <input className="m-0 w-[23px] relative h-[23px]" type="checkbox" />
          <div className="relative font-medium">
            Text Message (SMS) 2 days before appointment
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingNotificationView;
