import React, { FunctionComponent, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { RootState, AppDispatch } from "../../redux/store";
import {
  fetchPatientRecords,
  fetchUserRecords,
} from "../../redux/features/record/recordSlice";
import { setCurrentRecord } from "../../redux/features/nav/navSlice";
import { UserRole } from "../../redux/features/user/userTypes";
import logger from "../../util/logger";
import Button from "@mui/material/Button";
import { format } from "date-fns";

interface PatientInfoRecordViewProps {
  openRecordView: (type: string) => void;
}

const PatientInfoRecordView: FunctionComponent<PatientInfoRecordViewProps> = ({
  openRecordView,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const currentPatient = useSelector(
    (state: RootState) => state.nav.currentPatient
  );
  const patientRecords = useSelector(
    (state: RootState) => state.record.patientRecords
  );
  logger.log(patientRecords);
  useEffect(() => {
    if (currentPatient) {
      dispatch(
        fetchPatientRecords({ userId: currentPatient.id, type: "owner" })
      );
    }
  }, [dispatch, currentPatient]);

  const handleViewClick = (record: any) => {
    dispatch(setCurrentRecord(record));
    openRecordView(record.type);
  };

  const columnDefs: ColDef[] = [
    {
      headerName: "Date",
      field: "createDate",
      valueFormatter: (params) => new Date(params.value).toLocaleDateString(),
      flex: 1,
    },
    { headerName: "Record Type", field: "type", flex: 1 },
    { headerName: "Fee", field: "fee", flex: 1 },
    {
      headerName: "Doctor Name",
      field: "creator",
      valueFormatter: (params) =>
        params.value
          ? `${params.value.firstName} ${params.value.lastName}`
          : "N/A",
      flex: 1,
    },
    {
      headerName: "Action",
      field: "id",
      cellRenderer: (params: ICellRendererParams) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleViewClick(params.data)}
        >
          View
        </Button>
      ),
      flex: 1,
    },
  ];

  return (
    <div className="ag-theme-alpine" style={{ height: 400, width: "100%" }}>
      <AgGridReact
        rowData={patientRecords}
        columnDefs={columnDefs}
        domLayout="autoHeight"
      />
    </div>
  );
};

export default PatientInfoRecordView;
